.main_container {
  width: 100%;
  float: left;
}

.image_card {
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  text-align: right;
  width: 9%;
}
.upload-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.atchimg {
  width: auto !important;
  margin-right: 11px;
  margin-top: 3px;
  padding: 1px 0px;
}

i.fas.fa-file-alt.filepreview {
  color: #1a1717;
  padding: 5px 1px;
  font-size: 71px;
}

.chat_footer {
  background: linear-gradient(to left, #2a8bf2 0%, #60a9f6 100%);
  padding: 20px 30px;
  display: flex;
  vertical-align: middle;
  display: -webkit-flex;
  align-items: center;
  position: absolute;
  width: calc(100% - 235px);
  bottom: 0px;
  right: 0px;
  z-index: 9;
}

.chat_footer .input-group {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 23px 0px 0px 23px;
  border: none;
  box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 0%), 0px 0px 2px 0px rgb(0 0 0 / 12%);
  padding: 10px 20px;
  display: flex;
  vertical-align: middle;
  display: -webkit-flex;
  align-items: center;
  float: left;
  width: 95%;
  overflow: hidden;
  border-block: none !important;
  outline: none !important;
}

.insidechatfooter {
  background-color: white;
  border-radius: 23px;
}

.option_view {
  position: absolute;
  width: 50px;
  z-index: 0;
  left: -6px;
  top: -160px;
  padding: 0px;
  margin: 0px;
  display: block;
}
ul.option_view li a img {
  width: auto;
  height: auto;
}


.option_view li {
  list-style-type: none;
  margin-top: 5px;
  display: block;
  transition: 0.5s all ease-in-out;
  position: relative;
}
.admnImgDwnld {
}

.sendbtn {
  border: 1px solid #c4bebe;
  border-radius: 50%;
  transition: 45;
  transform: rotate(-35deg);
  padding: 4px 5px;
}

.sendbtnchat {
  background: transparent;
  border: none;
}

.atchinput {
}

.chat_footer .input-group textarea {
  max-height: 100px;
  overflow-y: auto !important;
}
.scrollcss {
  scroll-behavior: smooth;
}
.three_attach {
}
.date_time_css {
  font-size: 9px;
}
.chat_footer textarea {
  width: 95%;
  outline: none;
  border: none;
  box-sizing: border-box;
  resize: none;
}

.accept_btn {
  width: auto;
  background-color: rgb(255 255 255);
  color: rgb(24 132 66);
  border: none;
  padding: 3px 5px;
  border-radius: 8px;
  border: 1px solid rgb(24 132 66);
  font-size: 13px;
  margin-top: 4px;
  margin-right: 8px;
}

.makecorrection_btn {
  width: auto;
  background-color: #ffffff;
  color: rgb(233 0 0);
  border: none;
  padding: 3px 4px;
  border-radius: 8px;
  border: 1px solid #ff061a;
  font-size: 13px;
}

.btnonimage {
  border: none;
  background-color: transparent;
}
.videodisp {
}

.uploaded_file {
  margin-left: 2rem;
  width: 15%;
}
.preview_img {
  height: 10%;
  width: 70%;
  margin: 21px 0px;
}

.atchimglbl {
  margin-right: 20px;
  position: relative;
  cursor: pointer;
}

i.fas.fa-times-circle.rm_icon1 {
  float: right;
  font-size: 31px;
  padding: 3px 15px;
}
.videopopup {
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: black;
  position: fixed;
  z-index: 99 !important;
}

.imgpopup {
  overflow-y: auto;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: black;
  position: fixed;
  z-index: 99 !important;
}
.imgseeinpop {
 height: 600px;
    width: auto;
    max-width: 72%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.sayflex {
  display: flex;
}
.acceptedtxt {
  color: #188442;
  font-size: 12px;
}
.checkfa {
  font-size: 12px;
  color: green;
  margin-top: 3px !important;
  padding: 0px 7px;
}

.p_outside_image_pop {
  text-align: center;
}

#other .playbtnvideo {
  padding: 7px 18px;
  border-radius: 50%;
  background: #ede3e3;
  opacity: 0.6;
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}

#you .playbtnvideo {
  padding: 7px 18px;
  border-radius: 50%;
  background: #ede3e3;
  opacity: 0.6;
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}

i.fas.fa-play.playinvideo {
  border: none;
  color: black;
  margin-bottom: 8px;
}

.downloadtxt {
  color: white;
}
.insidechatfooter #grow {
  max-height: 150px !important;
  overflow: auto;
}

.videodisplay {
}

.videoouterbox {
  width: 100%;
  height: 50%;
  padding: 6px 115px;
  background-color: #000000;
}
.imageouterbox {
  width: 100%;
  height: 50%;
  padding: 6px 115px;
  background-color: #000;
}
.option_view li input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
}


.message-content1 img {
  width: 300px;
  height: 168px;
  border: 1px solid rgb(232 232 232);
  border-radius: 2px;
}
.chat-sub-header{
  font-size: 14px;
    font-weight: 500 !important;
    text-align: left;
    padding-top: 4px !important;
    line-height: 20px !important
}
