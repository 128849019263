.flt{
  float:left;
  width:100%;
}
.flex{
  display:flex;
}
.main-containerI{
  padding: 4% 17%;
}
.rectangleInq{
    background-color: rgba(0, 150, 75, 1);
    padding:2%;
}
.fontclrInq{
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 500;
  font-size: 25px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  text-transform: uppercase;
}
.paraInq{
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 16px;
  color: rgba(0, 0, 0, 1);
  padding-top: 35px;
}
.btnstyInq{
  background-color: black;
text-align: center;
color: white;
padding: 2% 10%;
}
.btInq{
  padding:6% 2%;
 text-align: center;
}
.arrowInq{
  float: right;
  padding-left: 10px;
}
@media (min-width: 320px) and (max-width: 767px) {
  .main-containerI {
    padding: 4% 0%;
}
.paraInq{
 font-size: 10px;
}
.fontclrInq{
  
  font-size: 25px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  text-transform: uppercase;
}
}