.flt {
  float: left;
  width: 100%;
}
.main_containers {
  text-align: center;
  margin-top: 150px;
}
.thankyou_barC {
  font-weight: bold;
  background-color: rgba(0, 150, 75, 1);
  font-family: "Noto Sans JP", sans-serif;
  font-size: 25px;
  width: 75%;
  /* margin: 6px 134px; */
  color: white;
  padding: 15px 15px;
  text-align: center;
}
.thankyou_para_contact {
  background-color: white;
  font-family: "Noto Sans JP Bold";
  font-size: 25px;
  color: black;
  padding: 40px 74px 100px;
  text-align: left;
  /* margin-top: 1rem; */
}
