@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
.footer_container {
  padding: 20px 0px;
}

.footer_box {
  background-color: rgba(0, 150, 75, 1);
  border-radius: 6px;
  padding: 60px 228px 58px 38px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  color: rgb(255, 255, 255);
  position: relative;
  margin-top: 150px;
}

.footer_head {
  font-size: 24px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  letter-spacing: 2px;
  line-height: 43px;
  margin-bottom: 10px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 600;
}
.footer_cont {
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  letter-spacing: 2px;
  line-height: 19px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 600;
}
.footer_top {
  padding: 0% 0%;
}

.footer_man {
  width: 40%;
  position: absolute;
  right: 58px;
  bottom: 0px;
}

.footer_btn {
  display: flex;
  align-items: center;
  margin-top: -30px;
  margin-bottom: 60px;
}
.footer_logo {
  margin-top: 30px;
}
.footer_btn button {
  color: rgb(255, 255, 255);
  font-size: 14px;
  text-align: center;
  letter-spacing: 4px;
  line-height: 18px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  padding: 10px 5px;
  width: 250px;
  position: relative;
  border: 2px solid rgb(255, 255, 255);
  font-weight: 600;
}
.footer_btn .green {
  background-image: linear-gradient(
    44deg,
    rgba(0, 154, 77, 1) 0%,
    rgba(0, 159, 159, 1) 100%
  );
}
.footer_btn .black {
  background-color: rgba(46, 46, 46, 1);
  padding: 19px 5px;
  margin-left: 22px;
}

.footer_btn button::before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.footer_btm {
  padding: 65px 20px 20px 20px;
}
.footer_logo img {
  width: 170px;
}
.footer_logo img {
  height: 32px;
  width: 258px;
}
.footer_contact ul {
  padding: 0px;
  margin: 0px;
  text-align: center;
}
.footer_contact ul li {
  display: inline-block;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 1px;
  position: relative;
  padding: 0px 12px;
}
.footer_contact ul li:first-child::before {
  content: "";
  height: 15px;
  width: 2px;
  background-color: rgb(183 183 183);
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}
.footer_link {
  padding: 10px 29px 10px;
  position: relative;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
  font-family: "Lato", sans-serif;
}
.footer_l {
  margin-left: 0px;
}
.footer_l ul li {
  list-style: none;
}
.footer_link ul {
  padding: 0px;
  margin: 0px;
  text-align: center;
}

img.kk {
  position: absolute;
  width: 20%;
  margin-top: 28px;
}
.p {
  width: 20% !important;
}
.q {
  width: 100% !important;
  margin-left: 10px;
}
.s {
  width: 1%;
}
.t {
  width: 1%;
}
.footer_box_flex {
  margin-top: 10px;
}
.footer_link ul li {
  list-style-type: none;
  display: inline-block;
  font-size: 13px;
  letter-spacing: 1px;
  margin-right: 15px;
  margin-left: 17px;
}
.footer_link ul li a {
  color: rgba(0, 0, 0, 1);
}
.copyright {
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 1px;
  font-weight: 600;
  background-color: rgba(250, 250, 250, 1);
  padding: 10px 0px;
}
/* @media (min-width: 320px) and (max-width: 767px) {
  .footer_head {
    font-size: 33px!important;
  }
  .footer_top {
    padding: 0% 0%!important;
}
.footer_box{
  padding: 30px 30px 50px 0px!important;
}
} */
@media (max-width: 2000) {
  .footer_l {
    margin-left: 150px;
  }
}
.g {
  width: 20%;
}
