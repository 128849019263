.maincontainer_settings {
}

.three_buttons {
  width: 100%;
  margin: 30px 0px;
  display: flex;
  align-items: center;
}

.all_contnr {
}
.user_container{
  float:left;
  width: 100%;
}
.btn_css {
    border: none;
    width: 30%;
    background-color: #10486b;
    color: white;
    font-size: 16px;
    padding: 10px;
}
.three_buttons .btn_css:nth-child(2) {
    margin: 0% 5%;
}
.settings_main_container {
  background-color: #bacfcf;

  width: 100%;
}
.maincontainer_settings {
    padding-left: 235px;
}
.outermost_container {
  
  /* width: 100%;
  margin-top: 5rem; */

  width: 100%;
  /* padding-left: 235px; */
}
.flts {
  float: left;
  width: 100%;
}

.setting_heading {
  text-decoration: underline;

  font-size: 20px;
  margin-bottom: 2rem;
}

.settings_items {
  padding: 30px 40px;
}
.input_css {
  width: 100%;
  margin-top: 2px;
  /* margin-bottom: 2rem; */
  border-radius: 0px;
  border: none;
  padding: 12px 18px;
  background-color: rgba(234, 234, 234, 1);
}
.all3_pwd1{
  min-height: 102px;


  /* margin-bottom: 1rem; */

}


.all3_userid1{
  min-height: 108px;


  /* margin-bottom: 1rem; */

}


.all3_userid2{
  min-height: 130px;


  /* margin-bottom: 1rem; */

}

.all3_email1{
  min-height: 102px;


  /* margin-bottom: 1rem; */

}
.label_css {
  font-size: 18px;
}

.submit_btn {
  background: #10486b;
  border: 1px solid #10486b;
  outline: 0;
  color: #fff;
  font-weight: 600;
  width: 32%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 12px;
}

.submit_btn1 {
  background: #10486b;
  border: 1px solid #10486b;
  outline: 0;
  color: #fff;
  font-weight: 600;
  width: 32%;
  padding-top: 10px;
  padding-bottom: 10px;
  /* margin-top: 15px; */
}
.outermost_container {
     padding: 80px 0px 0px;
    margin-bottom: 20px;
}
.all_contnr {
    width: 60%;
    margin: 0% auto;
    font-family: 'Zen Kaku Gothic New', sans-serif;
}

@media (min-width: 320px) and (max-width: 767px) {
  .sidebar {
    width: 40%;
  }
  .sidebar_nav {
    height: 100%;
    width: 40%;
  }
  .outermost_container {
    padding-left: 147px;
  }
  .btn_css {
    height: 65px;
    width: 99%;
    font-size: 16px;
    /* margin-right: 78px; */
    padding: 10px 17px;
    margin-bottom: 4px;
  }

  .settings_main_container {
    width: 100%;
  }

  .settings_items {
    padding: 20px 8px;
  }

  .input_css {
    width: 100%;
  }

  .submit_btn {
  
    width: 65%;}
  
}
