@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

.flt {
  float: left;
  width: 100%;
}
/* .header-container.flt {
    padding: 3% 3% 0%!important;
} */
.btn_ico {
  margin-left: 10px !important;
}
.flex_div {
  display: flex;
  align-items: flex-end;
}
.nextbtnH {
  width: 90px !important;
  background-color: rgba(0, 165, 83, 1);
  border: none;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 12px !important;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  padding: 10px;
}
i.fas.fa-times {
  margin-top: 0px !important;
}
.arrow_header {
  float: right !important;
}
.blockview {
  display: flex;
}
.button-icons {
  border-radius: 100%;
  background-color: rgba(0, 165, 83, 1);
  text-align: center;
  border: none;
}
.contact-icons {
  border-radius: 100%;
  padding: 0px 5px 11px 7px;
  color: white;
  height: 68%;
  font-size: 11px;
}
.logo a {
  width: 5%;
  text-decoration: none;
  color: rgba(0, 0, 0, 1);
  font-family: "Raleway", sans-serif;
  font-size: 19px;
  font-weight: 700;
}
.nav_links li a {
  padding: 0px 0px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
  font-size: 14px;
  /* color: rgb(99, 87, 87); */
  color: rgb(0, 0, 0);
  text-decoration: none;
}
/* .arrow_header1{
  margin-bottom: 10px;
}
.arrow_header1 {
    float: right;
    position: absolute;
    top: 50%;
    left: 50%;
    /* transform: translate(547px, 236px); */

.header_btn {
  position: relative;
}
.header_btn i {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
/* ul li {
  list-style-type: none;
  text-align: center;
  padding: 0px 5px;
} */
ul.nav_links.blockview li {
  list-style-type: none;
  text-align: center;
  padding: 0px 5px;
  margin: 0px 8px;
}
.clrchange {
  color: rgba(186, 186, 186, 1);
  /* margin-left: 13px; */
  font-size: 13px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
}
.hide_desktop {
  display: none;
}
.header_button {
  /* padding: 0px 9px; */
  margin-left: 8px;
}

.header_btn {
  background-color: #2da553;
  color: #fff;
  border: none;
  height: 54px;
  font-size: 14px;
  width: 171px;
  line-height: 19px;
  font-weight: 700;
}
li.navbar-toggler {
  /* padding: 1px 5px;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: box-shadow .15s ease-in-out; */
  padding: 0px 22px;
}

.header_btn i {
  /* margin-left: 10px; */
  margin-left: 20px;
  margin-top: 0px !important;
}

.header_flex {
  display: flex;
  align-items: center;
}
.header-container {
  padding: 20px 50px;
}
.nav_links.blockview {
  margin-bottom: 0px;
  padding: 0px 0px 0px 30px;
}
.links.flex_div {
  float: right;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

@media (min-width: 320px) and (max-width: 767px) {
  .main_container1 {
    width: 1%;
  }

  .nav_links {
    padding: 0px;
  }
  .links {
    text-align: center !important;
  }
  .nextbtnH {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .blockview {
    display: block;
  }
  .hide_desktop {
    display: block;
  }
  .flex_div {
    display: block;
  }
  .btn_center {
    text-align: center;
  }
  .header_btn {
    display: revert;
    margin: 10px 0px;
  }
  .header_logo_ico {
    font-size: 10px;
    width: 50px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* .navbar-toggler {
    padding: .0rem .0rem !important;
  } */
  .nextbtnH {
    width: 68px;
    font-size: 10px;
  }
  .nav_links {
    padding: 0px;
  }
  .nav_links li a {
    font-size: 10px;
  }

  .nav_links li a {
    padding: 0px 0px;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: 500;
    font-size: 10px !important;
    color: rgb(99, 87, 87);
    text-decoration: none;
  }

  .header_btn {
    padding: 8px 4px;
    font-size: 9px;
  }
  .header_btn i {
    margin-top: 1px !important;
  }
  .mobileMenu_btn {
    color: rgba(0, 1, 159, 1);
  }
  .mobileMenu_close {
    float: right;
    padding-right: 10px;
    padding-top: 5px;
  }
  .mobileMenu_btn {
    padding: 4% 81% !important;
  }
  .hide_desktop {
    display: none;
  }
  .nextbtnH {
    padding: 8px;
    font-size: 7px;
  }
  .button-icons {
    font-size: 7px;
  }

  .header-container {
    padding: 20px 10px;
  }
  .nav_links.blockview {
    padding: 0px;
  }
  .clrchange {
    font-size: 9px;
  }
  li.navbar-toggler {
    padding: 0px 5px;
  }

  .header-container .col-sm-3 {
    padding-right: 0px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hide_desktop {
    display: none;
  }
  .header-container {
    padding: 20px;
  }
  .nav_links.blockview {
    padding: 0px;
  }
  .nav_links li a {
    font-size: 11px;
  }
  .clrchange {
    font-size: 10px;
  }
  li.navbar-toggler {
    padding: 0px 10px;
  }
  .header_btn {
    padding: 8px 4px;
    font-size: 9px;
  }
  .header_btn i {
    margin-top: 1px !important;
  }
}

.filler {
  flex-grow: 1;
}

/* ----------- Non-Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .header-container {
    padding: 20px 30px;
  }
  .nav_links.blockview {
    padding: 0px 0px 0px 10px;
  }
  li.navbar-toggler {
    padding: 0px 10px;
  }
  .header_btn {
    padding: 4px;
  }
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .header-container {
    padding: 20px 30px;
  }
  .nav_links.blockview {
    padding: 0px 0px 0px 10px;
  }
  li.navbar-toggler {
    padding: 0px 10px;
  }
  .header_btn {
    padding: 0px 13px;
    width: 100%;
    height: 41px;
    font-size: 14px;
    line-height: 14px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .header_btn {
    height: 40px !important;
    width: 80px !important;
    line-height: initial;
  }

  .point_image img {
    width: 100% !important;
  }
}
