/* .navbar {
  width: 17%;
  background-color: rgb(7 72 107);
  height: 83px;

  display: flex;
  justify-content: start;
  align-items: center;
}

.menu-bars {
  color: white;

  margin-left: 0rem;
  font-size: 3rem;
  background: none;
}

.nav-menu {
  background-color: rgb(7 72 107);
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: black;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #ffffff;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: rgb(7 72 107);
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  margin-left: 16px;
} */

.unreadmsgs {
  font-size: 10px;
  border: 1px solid skyblue;
  border-radius: 50%;
  padding: 2px 7px;
  background-color: green;
}
.sidebar {
  background-color: rgb(7 72 107);
  float: left;
  width: 235px;
  position: fixed;
  left: 0px;
  top: 0px;
}
.os-content-glue {
  height: auto !important;
}
.sidebar_logo {
  padding: 40px 35px;
  box-shadow: 1px 2px #888888;
}

/* .nav_icon i {
    font-size: 27px !important;
    color: white;
} */

.nav_icon i {
  font-size: 22px !important;
  color: white;
  margin-block: auto;
  padding: -1px 1px;
}
.sidebar_nav {
  box-shadow: 1px 2px #888888;

  position: fixed !important;
  width: 235px;
  height: calc(100vh - 88px);
  left: 0px;
  top: 115px;
  background-color: rgb(7 72 107);
}
.sidebar_nav ul li a {
  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
}
.sidebar_nav ul {
  padding: 0px;
}
.sidebar_nav ul li {
  padding: 15px 0px;
}
.sidebar_nav ul li a {
  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  display: flex;
  align-items: center;
}

/* .nav_icon {
    text-align: right;
    padding: 10px 20px;
    border-radius: 0px 30px 30px 0px;
    width: 40%;
    transition: 0.3s all ease-in-out;
} */

.nav_icon {
  text-align: right;
  padding: 10px 40px;
  border-radius: 0px 30px 30px 0px;
  width: 40%;
  transition: 0.3s all ease-in-out;
  margin-right: 10px;
}

.sidebar_nav ul li a:hover .nav_icon {
  background-color: rgba(196, 196, 196, 1);
  transition: 0.3s all ease-in-out;
}
.nav_icon i {
  font-size: 18px;
}
/* .nav_txt {
    font-size: 16px;

    padding-left: 15px;
    width: 60%;
} */

.nav_txt {
    width: 32%;
    text-align: left;
    font-weight:500;
}
.sidebar_nav ul li .active .nav_icon {
  background-color: rgba(196, 196, 196, 1);
  transition: 0.3s all ease-in-out;
}
.version {
  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  width: 70%;
  padding: 15px 0px;
}
.version img {
  margin-bottom: 10px;
}
.page_container {
  width: 100%;
  padding-left: 235px;
}
.page_head {
  display: flex;
  align-items: center;
  padding: 18px 35px;
  box-shadow: 0px 3px 6px 0px rgb(0 147 148 / 50%);
}
.page_title {
  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-size: 25px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 3px;
  text-transform: uppercase;
}
.profile_icon {
  margin-right: 25px;
}
.filler {
  flex-grow: 1;
}
.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  background: rgb(255 255 255 / 40%) !important;
}
.profile_name {
  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-size: 15px;
  color: rgb(0, 0, 0);
}
.dashboard_drops {
  display: flex;
  align-items: center;
  padding: 17px 36px 17px 36px;
  border-bottom: 1px solid #e6eaed;
}
.drops_name svg {
  color: rgba(0, 147, 148, 1);
  font-size: 10px;
}
.css-yk16xz-control {
  background-color: rgba(227, 226, 226, 1) !important;
  border-radius: 12px !important;
  font-family: 'Zen Kaku Gothic New', sans-serif !important;
  font-size: 12px !important;
  color: rgba(0, 0, 0, 1) !important;
}
.dashboard_drops {
  display: flex;
  align-items: center;
}
.drops_name {
  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 0.3px;
  margin-bottom: 5px;
}

.dashboard_left_head {
  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-size: 32px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.dashboard_cont {
  display: flex;
}
.dashboard_left {
  width: 75%;
  padding: 15px 15px 15px 35px;
}
.dashboard_right {
  width: 25%;
  padding: 15px 10px;
}
.dashboard_right_head {
  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-size: 32px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.sidelogo {
  height: auto;
  vertical-align: middle;
  margin-left: 39px;
  width: 50%;
}
