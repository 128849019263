ul.docout-root-wrap a {
    color: rgb(0, 0, 0);
    text-decoration: none;
}
.section-content img {
    width: 100%;
    position: inherit !important;
}
.whitebg a img:nth-child(2) {
    width: 80px;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
}

.whitebg a {
    position: relative;
    float: left;
    width: 100%;
}