.flt {
  float: left;
  width: 100%;
}
.bt_registration button::before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.flex {
  display: flex;
}
.margin_lft {
  margin-left: 1px;
}
.main-containerReg {
  padding-top: 90px;
}
.rectangleReg {
  background-color: rgba(0, 150, 75, 1);
  border-left: 15px solid rgba(0, 132, 66, 1);
  padding: 1%;
}
.fontclrReg {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
  font-size: 25px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  text-transform: uppercase;
}
.info {
  padding: 4% 0%;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 16px;
  color: rgba(59, 59, 59, 1);
  text-align: center;
}
.member-info {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
  /* padding: 0% 0% 2% 0%; */
  margin-bottom: 60px;
}
.member-information {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
  /* padding: 4% 0% 2% 0%; */
  margin: 50px 0px;
}
.member {
  font-weight: 600;
}
.contact_group {
  margin-bottom: 35px;
}

.contact-group label {
  max-width: 100%;
  margin-bottom: 5px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
  display: block !important;
  /* padding: 10px; */
}
.lblspace {
  padding-top: 10px;
}
.contact-group label span {
  background-color: rgba(217, 0, 0, 1);
  padding: 3px 12px;
  font-weight: bold;
  margin-left: 15px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 11px;
  color: rgba(255, 255, 255, 1);
  float: right;
}

.contact-group input {
  float: left;
  width: 100%;
  border: none;
  padding: 12px 18px;
  background-color: rgba(234, 234, 234, 1);
  border-radius: 5px;
}
.contact-form {
  margin-top: 24px;
}
select {
  float: left;
  width: 100%;
  border-radius: 0px;
  border: none;
  padding: 12px 18px;
  background-color: rgba(234, 234, 234, 1);
}

.alpha {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 14px;
  color: rgba(112, 112, 112, 1);
}
.clrReg {
  color: rgba(0, 129, 239, 1);
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 16px;
  font-weight: 500;
}
.starReg {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  font-weight: 500;
}
.line3Reg {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  font-weight: 500;
}
.checklbl {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 18px;
  color: rgba(0, 0, 0, 1);
}
.exampReg {
  /* text-align:center; */
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
  font-weight: 500;
}
.btnsty_register {
  background-color: black;
  text-align: center;
  color: white;
  padding: 21px 90px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}
.bt_registration {
  padding: 6% 2%;
  text-align: center;
}
.arrowcont {
  float: right;
  margin-left: 57px;
}
.reg-btn {
  text-align: center;
  padding: 7% 0%;
}
.agrmntReg {
  text-align: center;
  line-height: 2;
}
.contact_line {
  padding: 0% 2%;
  word-break: keep-all;
}
.disable {
  pointer-events: none;
  opacity: 0.6;
}
.reg_policy {
  font-size: 14px;
}
.contact_background {
  background-color: #6c757d40;
  position: absolute;
  width: 23%;
  padding: 16% 10%;
  z-index: 9;
  opacity: 1;
  border-radius: 3%;
}
.contact_background1 {
  background-color: #6c757d40;
  position: absolute;
  width: 23%;
  padding: 17% 10%;
  z-index: 9;
  opacity: 1;
  border-radius: 3%;
}
.registration_box {
  background-color: rgb(234 234 234);
  /* border-radius: 2px; */
}
.registration_box1 {
  background-color: rgb(234 234 234);
  /* border-radius: 20px 20px 0px 0px; */
  position: relative;
}
/* .registration_box2{
  background-color: rgb(234 234 234);
  border-radius: 0px 0px 20px 20px;
} */

.registration_box2 {
  background-color: rgb(234 234 234);
  border-radius: 0 0 20px 20px;
  border-bottom: 50px solid #eaeaea;
}

.registration_box1::before {
  position: absolute;
  left: 0px;
  top: -55px;
  content: "";
  background-color: #eaeaea;
  width: 100%;
  height: 55px;
  border-radius: 20px 20px 0px 0px;
}
.registration_space {
  padding-left: 50px !important;
  padding-bottom: 25px;
  word-break: break-word;
}
.registration_space1 {
  padding-left: 50px !important;
}

/* @media (min-width: 1200px) and (max-width: 1600px) {
.contact_background {
   
    padding: 17% 10%;
  
}
  } */

@media (min-width: 320px) and (max-width: 767px) {
  .regcon_main_head_txt {
    font-weight: bold;
  }

  .registration_box1::before {
    background-color: #ffffff;
  }
  .registration_box {
    background-color: #ffffff;
    /* border-radius: 2px; */
  }
  .registration_box1 {
    background-color: #ffffff;
  }
  /* .registration_box2 {
       background-color: rgb(255 255 255);
 
} */
  /* .registration_box2{
  background-color: rgb(234 234 234);
  border-radius: 0px 0px 20px 20px;
} */

  .registration_box2 {
    border-bottom: 50px solid #ffffff;
    background-color: #ffffff;
  }
  .contact-group label span {
    float: revert;
  }

  .contact_background {
    display: none;
  }
  .main-containerReg {
    padding: 0% 0%;
  }
  .info {
    padding: 5% 0%;
    font-size: 16px;
  }
  .member-info {
    font-size: 16px;
  }
  .registration_space {
    padding-left: 12px !important;
  }
  .clrReg {
    font-size: 12px;
  }
  .registration_space1 {
    padding-left: 12px !important;
  }
}
/* #target2{
    display:block;
} */

input {
  outline: none;
}
.regcon_container {
  background-color: #ffff;
}
.regcon_bar {
  margin-top: 32px;
  background-color: rgba(0, 150, 75, 1);
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 25px;
  color: white;
  text-align: left;
  padding: 15px 25px;
  font-weight: bold;
  width: 100%;
}

.regcon_items_container {
  padding: 0px 87px;
}
.regcon_main_head_txt {
  font-weight: bold;
  margin-top: 30px;
  font-size: 20px;
  text-align: left;
  font-family: "Zen Kaku Gothic New", sans-serif;
}
.regcon_sub_head_txt {
  font-size: 16px;
  text-align: left;
  color: rgba(112, 112, 112, 1);
  font-family: "Zen Kaku Gothic New", sans-serif;
}
.regcon_lbls {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: rgba(0, 0, 0, 1);
}

.regcon_displayed_txt {
  color: rgba(144, 144, 144, 1);
  font-size: 15px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.regcon_det_cont {
  float: left;
  padding: 26px 0px;
  width: 100%;
  text-align: left;
}

.regcon_head1 {
  margin-top: 3rem;
  margin-bottom: 2rem;
}
.regcon_head2 {
  margin-top: 10rem;
  margin-bottom: 2rem;
}

.regcon_head3 {
  margin-top: 10rem;
  margin-bottom: 2rem;
}
.regcon_flex {
  margin-top: 10rem;
}
.regcon_btn {
  margin-right: 2rem;
  font-family: "Zen Kaku Gothic New", sans-serif;
  margin-left: 8rem;
  background: rgba(0, 0, 0, 1);
  color: rgba(255, 255, 255, 1);
  border: none;
  width: 25%;
  padding: 18px 13px;
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}
.regcon_btn1 {
  font-family: "Zen Kaku Gothic New", sans-serif;
  margin-left: 1rem;
  background: rgba(0, 0, 0, 1);
  color: rgba(255, 255, 255, 1);
  border: none;
  width: 28%;
  padding: 18px 13px;
  text-align: center;
  text-decoration: none;
  font-size: 15px;
}

hr.new1 {
  border: 1px solid #d2d2d2;
  opacity: 1;
}

.invalid-feedback {
  display: block !important;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
/* .state_option{
  color: green;
} */
.select {
  word-wrap: unset !important;
}

.contact-form .row {
  padding-left: 12px;
}
.registration_space select {
  background-image: linear-gradient(45deg, transparent 50%, rgb(64 64 64) 60%),
    linear-gradient(135deg, rgb(64 64 64) 40%, transparent 50%) !important;
  background-position: calc(100% - 30px) 20px, calc(100% - 20px) 20px, 100% 0;
  background-size: 10px 10px, 10px 10px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
}

@media (min-width: 768px) {
  .registration_box,
  .registration_box1,
  .registration_box2 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
