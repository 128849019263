@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

.landing_container {
  position: relative;
}

button.signup_btn div.fltlft {
  padding-left: 27px;
}

.home_img {
  /* width: 100%; */
}
.main_heading_service {
  background-color: rgba(199, 199, 199, 1);
  padding: 45px 0px 130px 45px;
  font-size: 20px;
  color: rgba(112, 112, 112, 1);
  margin-bottom: 22px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.main_subcontent_service b {
  font-size: 24px;
}

.main_subcontent_service span {
  font-size: 24px;
}

.main_subcontent_service {
  line-height: 27px;
  font-weight: 400;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

button.signup_btn div.fltlft {
  padding-left: 27px;
}

.home_img {
  /* width: 100%; */
}

.main_heading_service {
  background-color: rgba(199, 199, 199, 1);
  padding: 45px 0px 130px 45px;
  font-size: 20px;
  color: rgba(112, 112, 112, 1);
  margin-bottom: 22px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.main_subcontent_service b {
  font-size: 24px;
}

.main_subcontent_service span {
  font-size: 24px;
}

.main_subcontent_service {
  line-height: 27px;
  font-weight: 400;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

button.signup_btn div.fltlft {
  padding-left: 27px;
}

.home_img {
  /* width: 100%; */
}

.main_heading_service {
  background-color: rgba(199, 199, 199, 1);
  padding: 45px 0px 130px 45px;
  font-size: 20px;
  color: rgba(112, 112, 112, 1);
  margin-bottom: 22px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.main_subcontent_service b {
  font-size: 24px;
}

.main_subcontent_service span {
  font-size: 24px;
}

.main_subcontent_service {
  line-height: 27px;
  font-weight: 400;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

button.signup_btn div.fltlft {
  padding-left: 27px;
}

.home_img {
  /* width: 100%; */
}

.main_heading_service {
  background-color: rgba(199, 199, 199, 1);
  padding: 45px 0px 130px 45px;
  font-size: 20px;
  color: rgba(112, 112, 112, 1);
  margin-bottom: 22px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.main_subcontent_service b {
  font-size: 24px;
}

.main_subcontent_service span {
  font-size: 24px;
}

.main_subcontent_service {
  line-height: 27px;
  font-weight: 400;
  font-family: "Noto Sans JP", sans-serif;
}

button.signup_btn div.fltlft {
  padding-left: 27px;
}

.home_img {
  /* width: 100%; */
}

.main_heading_service {
  background-color: rgba(199, 199, 199, 1);
  padding: 45px 0px 130px 45px;
  font-size: 20px;
  color: rgba(112, 112, 112, 1);
  margin-bottom: 22px;
  font-family: "Noto Sans JP", sans-serif;
}

.main_subcontent_service b {
  font-size: 24px;
}

.main_subcontent_service span {
  font-size: 24px;
}

.main_subcontent_service {
  line-height: 27px;
  font-weight: 400;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

button.signup_btn div.fltlft {
  padding-left: 27px;
}

.home_img {
  /* width: 100%; */
}

.main_heading_service {
  background-color: rgba(199, 199, 199, 1);
  padding: 45px 0px 130px 45px;
  font-size: 20px;
  color: rgba(112, 112, 112, 1);
  margin-bottom: 22px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.main_subcontent_service b {
  font-size: 24px;
}

.main_subcontent_service span {
  font-size: 24px;
}

.main_subcontent_service {
  line-height: 27px;
  font-weight: 400;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

button.signup_btn div.fltlft {
  padding-left: 27px;
}

.home_img {
  /* width: 100%; */
}

.main_heading_service {
  background-color: rgba(199, 199, 199, 1);
  padding: 45px 0px 130px 45px;
  font-size: 20px;
  color: rgba(112, 112, 112, 1);
  margin-bottom: 22px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.main_subcontent_service b {
  font-size: 24px;
}

.main_subcontent_service span {
  font-size: 24px;
}

.main_subcontent_service {
  line-height: 27px;
  font-weight: 400;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

button.signup_btn div.fltlft {
  padding-left: 27px;
}

.home_img {
  /* width: 100%; */
}

.main_heading_service {
  background-color: rgba(199, 199, 199, 1);
  padding: 45px 0px 130px 45px;
  font-size: 20px;
  color: rgba(112, 112, 112, 1);
  margin-bottom: 22px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.main_subcontent_service b {
  font-size: 24px;
}

.main_subcontent_service span {
  font-size: 24px;
}

.main_subcontent_service {
  line-height: 27px;
  font-weight: 400;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

button.signup_btn div.fltlft {
  padding-left: 0px;
}

.home_img {
  /* width: 100%; */
}

.main_heading_service {
  background-color: rgba(199, 199, 199, 1);
  padding: 45px 0px 130px 45px;
  font-size: 20px;
  color: rgba(112, 112, 112, 1);
  margin-bottom: 22px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.main_subcontent_service b {
  font-size: 24px;
}

.main_subcontent_service span {
  font-size: 24px;
}

.main_subcontent_service {
  line-height: 27px;
  font-weight: 400;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

button.signup_btn div.fltlft {
  padding-left: 27px;
}

.home_img {
  /* width: 100%; */
}

.main_heading_service {
  background-color: rgba(199, 199, 199, 1);
  padding: 45px 0px 130px 45px;
  font-size: 20px;
  color: rgba(112, 112, 112, 1);
  margin-bottom: 22px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.main_subcontent_service b {
  font-size: 24px;
}

.main_subcontent_service span {
  font-size: 24px;
}

.main_subcontent_service {
  line-height: 27px;
  font-weight: 400;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

button.signup_btn div.fltlft {
  padding-left: 27px;
}

.home_img {
  /* width: 100%; */
}

.main_heading_service {
  background-color: rgba(199, 199, 199, 1);
  padding: 45px 0px 130px 45px;
  font-size: 20px;
  color: rgba(112, 112, 112, 1);
  margin-bottom: 22px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.main_subcontent_service b {
  font-size: 24px;
}

.main_subcontent_service span {
  font-size: 24px;
}

.main_subcontent_service {
  line-height: 27px;
  font-weight: 400;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

button.signup_btn div.fltlft {
  padding-left: 27px;
}

.home_img {
  /* width: 100%; */
}

.main_heading_service {
  background-color: rgba(199, 199, 199, 1);
  padding: 45px 0px 130px 45px;
  font-size: 20px;
  color: rgba(112, 112, 112, 1);
  margin-bottom: 22px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.main_subcontent_service b {
  font-size: 24px;
}

.main_subcontent_service span {
  font-size: 24px;
}

.main_subcontent_service {
  line-height: 27px;
  font-weight: 400;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

button.signup_btn div.fltlft {
  padding-left: 27px;
}

.home_img {
  /* width: 100%; */
}

.main_heading_service {
  background-color: rgba(199, 199, 199, 1);
  padding: 45px 0px 130px 45px;
  font-size: 20px;
  color: rgba(112, 112, 112, 1);
  margin-bottom: 22px;
  font-family: "Noto Sans JP", sans-serif;
}

.main_subcontent_service b {
  font-size: 24px;
}

.main_subcontent_service span {
  font-size: 24px;
}

.main_subcontent_service {
  line-height: 27px;
  font-weight: 400;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

button.signup_btn div.fltlft {
  padding-left: 27px;
}

.home_img {
  /* width: 100%; */
}

.main_heading_service {
  background-color: rgba(199, 199, 199, 1);
  padding: 45px 0px 130px 45px;
  font-size: 20px;
  color: rgba(112, 112, 112, 1);
  margin-bottom: 22px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.main_subcontent_service b {
  font-size: 24px;
}

.main_subcontent_service span {
  font-size: 24px;
}

.main_subcontent_service {
  line-height: 27px;
  font-weight: 400;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

button.signup_btn div.fltlft {
  padding-left: 27px;
}

.home_img {
  /* width: 100%; */
}

.main_heading_service {
  background-color: rgba(199, 199, 199, 1);
  padding: 45px 0px 130px 45px;
  font-size: 20px;
  color: rgba(112, 112, 112, 1);
  margin-bottom: 22px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.main_subcontent_service b {
  font-size: 24px;
}

.main_subcontent_service span {
  font-size: 24px;
}

.main_subcontent_service {
  line-height: 27px;
  font-weight: 400;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

button.signup_btn div.fltlft {
  padding-left: 27px;
}

.home_img {
  /* width: 100%; */
}

.main_heading_service {
  background-color: rgba(199, 199, 199, 1);
  padding: 45px 0px 130px 45px;
  font-size: 20px;
  color: rgba(112, 112, 112, 1);
  margin-bottom: 22px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.main_subcontent_service b {
  font-size: 24px;
}

.main_subcontent_service span {
  font-size: 24px;
}

.main_subcontent_service {
  line-height: 27px;
  font-weight: 400;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

button.signup_btn div.fltlft {
  padding-left: 27px;
}

.home_img {
  /* width: 100%; */
}

.main_heading_service {
  background-color: rgba(199, 199, 199, 1);
  padding: 45px 0px 130px 45px;
  font-size: 20px;
  color: rgba(112, 112, 112, 1);
  margin-bottom: 22px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.main_subcontent_service b {
  font-size: 24px;
}

.main_subcontent_service span {
  font-size: 24px;
}

.main_subcontent_service {
  line-height: 27px;
  font-weight: 400;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

button.signup_btn div.fltlft {
  padding-left: 27px;
}

.home_img {
  /* width: 100%; */
}

.main_heading_service {
  background-color: rgba(199, 199, 199, 1);
  padding: 45px 0px 130px 45px;
  font-size: 20px;
  color: rgba(112, 112, 112, 1);
  margin-bottom: 22px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.main_subcontent_service b {
  font-size: 24px;
}

.main_subcontent_service span {
  font-size: 24px;
}

.main_subcontent_service {
  line-height: 27px;
  font-weight: 400;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

button.signup_btn div.fltlft {
  padding-left: 27px;
}

.home_img {
  /* width: 100%; */
}

.main_heading_service {
  background-color: rgba(199, 199, 199, 1);
  padding: 45px 0px 130px 45px;
  font-size: 20px;
  color: rgba(112, 112, 112, 1);
  margin-bottom: 22px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.main_subcontent_service b {
  font-size: 24px;
}

.main_subcontent_service span {
  font-size: 24px;
}

.main_subcontent_service {
  line-height: 27px;
  font-weight: 400;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

button.signup_btn div.fltlft {
  padding-left: 27px;
}

.home_img {
  /* width: 100%; */
}

.main_heading_service {
  background-color: rgba(199, 199, 199, 1);
  padding: 45px 0px 130px 45px;
  font-size: 20px;
  color: rgba(112, 112, 112, 1);
  margin-bottom: 22px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.main_subcontent_service b {
  font-size: 24px;
}

.main_subcontent_service span {
  font-size: 24px;
}

.main_subcontent_service {
  line-height: 27px;
  font-weight: 400;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

button.signup_btn div.fltlft {
  padding-left: 27px;
}

.home_img {
  /* width: 100%; */
}

.main_heading_service {
  background-color: rgba(199, 199, 199, 1);
  padding: 45px 0px 130px 45px;
  font-size: 20px;
  color: rgba(112, 112, 112, 1);
  margin-bottom: 22px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.main_subcontent_service b {
  font-size: 24px;
}

.main_subcontent_service span {
  font-size: 24px;
}

.main_subcontent_service {
  line-height: 27px;
  font-weight: 400;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.main_heading_div {
  font-size: 24px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 2px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.main_subcontent_div {
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  font-family: "Zen Kaku Gothic New", sans-serif;
  line-height: 2;
  font-weight: 400;
}

.main_content_div {
  float: left;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
}

.main_heading_service {
  background-color: rgba(199, 199, 199, 1);
  padding: 45px 0px 130px 45px;
  font-size: 20px;
  color: rgba(112, 112, 112, 1);
  margin-bottom: 22px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.main_subcontent_service b {
  font-size: 24px;
}

.main_subcontent_service span {
  font-size: 24px;
}

.main_subcontent_service {
  line-height: 27px;
  font-weight: 400;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.main_heading_div {
  font-size: 24px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 2px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.main_subcontent_div {
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  font-family: "Zen Kaku Gothic New", sans-serif;
  line-height: 2;
}

.main_content_div {
  float: left;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
}

img,
svg {
  width: 100%;
}

.home2 {
  margin-top: 8rem;
  padding: 15px;
}

.font1 {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: bold;
  font-size: 40px;
  color: #000;
  letter-spacing: 5px;
  text-align: left;
  margin-left: 8rem;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-color: rgb(71 194 129);
  text-decoration-thickness: 5px;
}

.font2 {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 16px;
  width: 75%;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 1em;
  margin-left: 8rem;
  margin-top: 2rem;
  font-weight: 500;
}

.greenbtn {
  background: linear-gradient(to left, #46a0a0 0%, #1c9948 100%);
  width: 54%;
  margin-top: 2rem;
  color: rgba(255, 255, 255, 1);
  border: none;
  font-weight: 500;
  padding: 9px 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.greenbtn1 {
  font-weight: 500;
  background: linear-gradient(to left, #46a0a0 0%, #1c9948 100%);
  width: 25%;
  margin-top: 2rem;
  color: rgba(255, 255, 255, 1);
  border: none;
  padding: 9px 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  /* background: linear-gradient(to left, #46a0a0 0%, #1c9948 100%);
  width: 23%;
  margin-top: 3rem;
  color: rgba(255, 255, 255, 1);
  border: none;
  padding: 10px 31px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  font-family: "Noto Sans JP Medium"; */
  /* margin-left: 11rem; */
}

.blackbtn {
  font-weight: 500;
  background-color: #000000;
  width: 54%;
  margin-top: 1rem;
  color: rgb(255, 255, 255);
  border: none;
  padding: 19px 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
}

.mycontainer {
  margin-top: 5rem;
}

.feature_bar {
  border-left: 15px solid rgba(0, 132, 66, 1);
  background-color: rgba(0, 150, 75, 1);
  height: 75px;
  width: 100%;
  padding: 0px 11px;
  /* background-color: rgba(0, 150, 75, 1);
  font-family: "Noto Sans JP Bold";
  font-size: 25px;
  color: white;
  padding: 15px 15px;
  text-align: left; */
}

.featuretext1 {
  color: rgba(255, 255, 255, 1);
  text-align: left;
  font-family: "Lato";
  font-size: 40px;
  float: left;
  margin-top: 7px;
}

.featuretext2 {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  float: left;
  font-size: 14px;
  padding: 15px 11px;
  margin-top: 7px;
}

.featureblackbtn {
  background-color: rgba(0, 0, 0, 1);
  width: 28%;
  margin-top: 1rem;
  color: rgb(255, 255, 255);
  border: none;
  padding: 17px 17px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

a.home_link {
  color: black;
  word-break: break-all;
}

.mycontainer1 {
  /* margin-top: 2rem; */
  background-color: rgba(247, 247, 247, 1);
  /* padding: 17px 1px; */
  padding: 0px 0px 40px 0px;

  /* margin-left: 7rem; */
}

.feature_tab {
  margin: 0px 86px;
}

.feature_tbl_num {
  font-family: "Lato";
  font-size: 91px;
  color: rgba(0, 165, 83, 1);
  /* float: right; */
  font-weight: 700;
}

.feature_tbl_container {
  margin-top: 2rem;
}

.fa,
.far,
.fas {
  font-family: "Font Awesome 5 Free";
  float: left;
  color: grey;
  margin-top: 10px;
}

.feature_tbl_txt {
  font-weight: bold;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 25px;
  float: left;
  margin: 4px;
}

.feature_tbl_engtxt {
  font-family: "Lato";
  font-size: 13px;
  color: gray;
  float: left;
  margin-top: 18px;
}

.feature_jap_txt {
  font-weight: 500;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 14px;
  float: left;
  color: #2d2828;
  text-align: left;
}

.news_container {
  margin-top: 5rem;
}

.news_container1 {
  margin-top: 5rem;
  /* margin-right: 11rem; */
  /* height: 54px; */
  /* padding: 0px 11px; */
}

.hrline {
  border-top: 2px solid grey;
}

.news {
  text-align: left;
  font-size: 40px;
  font-family: "Lato";
  font-weight: bolder;
  padding: 7px;
}

.date {
  font-size: 14px;
  padding: 1rem;
}

.japtxt {
  font-size: 9px;
  /* width: 35%; */

  padding: 1rem;
}

.news_padding {
  padding-bottom: 9px;
  margin-left: 20px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.news_padding_jap {
  padding-bottom: 10px;
  font-size: 14px;
}

.green_verticle_line {
  border-left: 3px solid #8bd38b;
  margin-left: 36px;
  margin-bottom: 9px;
  margin-top: 3px;
  padding: 0px 32px;
  display: table-caption;
}

.green_verticle_line_news {
  border-left: 8px solid green;
  height: 29px;
  margin-top: 22px;
}

span.\.green_verticle_line_news {
  border-left: 9px solid green;
  height: 30px;
  margin-top: 22px;
}

.news_text {
  font-family: "Zen Kaku Gothic New", sans-serif;
  margin-top: 26px;
  font-size: 14px;
  font-weight: 500;
}

.blackbtn1 {
  font-family: "Noto Sans JP";
  font-weight: 600;
  background-color: rgba(0, 0, 0, 1);
  width: 240px;
  margin-top: 2rem;
  color: rgb(255, 255, 255);
  border: none;
  padding: 17px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
}

td.datestyl {
  font-size: 16px;
  font-weight: 500;
  font-family: "Zen Kaku Gothic New", sans-serif;
  vertical-align: top;
}

a.home_link:hover {
  color: black;
}

.home_link td {
  display: inline-block;
  width: 373px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-size: 18px;
  font-family: "Zen Kaku Gothic New";
}

.logo_text {
  font-weight: bold;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 20px;
  text-align: center;

  /* margin-left: 9rem; */
}

.logo_images_contnr {
  background-color: white;
  width: 69%;
  margin-left: 5rem;
  margin-top: 2rem;
}

/* .clogo {

  width: 74px;


} */

.logo_img img {
  vertical-align: middle;
  margin-left: 9rem;
  height: 94px;
  width: 91px;
}

.logo_images_contnr1 {
  background-color: white;
  width: 85%;
  margin-left: 16rem;
  margin-top: 2rem;
}

.logo_img1 img {
  vertical-align: middle;
  margin-left: 4rem;
  height: 94px;
  width: 91px;
}

/* ////////////////CSS for QNA PAGE////////////// */
.FAQ_container {
  background-color: #ffff;
}

.FAQ_container .wrapper .alter:nth-child(odd) .a-btn {
  background-color: #ebe9e9;
}

.FAQ_container .wrapper .alter:nth-child(even) .a-btn {
  background-color: rgb(195 196 196);
}

.FnQ_bar {
  letter-spacing: 3px;

  font-size: 25px;
  font-weight: bold;
  border-left: 15px solid rgba(0, 132, 66, 1);
  background-color: rgba(0, 150, 75, 1);
  font-family: "Zen Kaku Gothic New", sans-serif;
  color: white;
  padding: 15px 15px;
  text-align: left;
  margin-top: 2rem;
}

.bar_container {
  margin-top: 4rem;
}

.faq_btn {
  background: linear-gradient(to left, #46a0a0 0%, #1c9948 100%);
  width: 25%;
  margin-top: 2rem;
  color: rgba(255, 255, 255, 1);
  border: none;
  padding: 9px 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;

  /* background: linear-gradient(to left, #46a0a0 0%, #1c9948 100%);
  width: 29%;
  color: rgba(255, 255, 255, 1);
  border: none;
  padding: 9px 39px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  /* / / margin-top: 3rem; / / */
  /* font-family: "Noto Sans JP Medium"; */
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
  line-height: 1.4;
}

.wrapper {
  width: 90%;
  margin: 88px auto;
}

.accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}

.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title {
  font-weight: 600;
  margin-left: 36px;

  cursor: pointer;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  background-color: rgba(219, 219, 219, 1);
  padding: 0em 0em !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-title:hover {
  color: black;
}

.accordion-content {
  word-break: break-all;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
  padding: 1em 1.5em;
  background-color: rgba(247, 247, 247, 1);
  font-size: 14px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

.btnpos {
  background-color: rgba(219, 219, 219, 1);
  border: none;
}

.accordion-title button {
  position: relative;
}

.accordion-title button::before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  content: "\f067" !important;
  color: rgb(0, 0, 0);
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
}

.accordion-title.open button::before {
  content: "\f068" !important;
}

.accor_btn {
  border: none;
}

.a-btn {
  background-color: rgba(219, 219, 219, 1);
}

.lblpadding {
  padding: 10px 21px;
  width: 92%;
  text-align: left;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 14px;
  font-weight: bold;
  word-break: break-all;
}

/* ///////////////OS PAGE CSS/////////////// */

.os_container {
  background-color: #ffff;
  margin-top: 32px;
}

.os_bar {
  letter-spacing: 3px;

  border-left: 15px solid rgba(0, 132, 66, 1);
  background-color: rgba(0, 150, 75, 1);
  width: 100%;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 25px;
  font-weight: bold;
  color: white;
  padding: 15px 15px;
  text-align: left;
}

.box_container {
  /* background-color: rgba(227, 227, 227, 1);
  height: 910px;
  width: 810px;
  margin: 58px 138px; */
  padding: 0% 0%;
}

.box_container1 {
  /* background-color: rgba(227, 227, 227, 1);
  height: 910px;
  width: 810px;
  margin: 58px 138px; */
  padding: 0% 10%;
}

.os_btn {
  background: linear-gradient(to left, #46a0a0 0%, #1c9948 100%);
  width: 25%;
  margin-top: 2rem;
  color: rgba(255, 255, 255, 1);
  border: none;
  padding: 9px 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  font-family: "Zen Kaku Gothic New", sans-serif;

  /* background: linear-gradient(to left, #46a0a0 0%, #1c9948 100%);
  width: 240px;
  height: 63px;
  color: rgba(255, 255, 255, 1);
  border: none;
  padding: 9px 39px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  margin-top: 1rem;

  font-family: "Noto Sans JP Medium"; */
}

/* ////////////////CSS for SEVICE PAGE////////////// */

.serv_container {
  background-color: #ffff;
  margin-top: 2rem;
}

.service_bar {
  margin-bottom: 53px;
  position: relative;
}

.service_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-129px, -21px);
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
  font-size: 35px;
  color: rgba(255, 255, 255, 1);
}

.service_content1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-129px, -21px);
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
  font-size: 35px;
  color: rgba(255, 255, 255, 1);
}

.service_content3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-259px, -21px);
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
  font-size: 35px;
  color: rgba(255, 255, 255, 1);
}

.service_content4 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-71px, -21px);
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
  font-size: 35px;
  color: rgba(255, 255, 255, 1);
}

.service_content5 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-30px, 0px);
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
  font-size: 35px;
  color: rgba(255, 255, 255, 1);
}

.circle_div {
  margin-top: 64px;
  margin-bottom: 26px;
}

.circle1 {
  width: 126px;
  height: 126px;
  border: solid 5px rgba(175, 175, 175, 1);
  border-radius: 50%;
  position: relative;
}

.clrcle1_text {
  position: absolute;
  top: 50%;
  left: 50%;
  word-break: keep-all;
  transform: translate(-34px, -11px);
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 20px;
  color: rgba(0, 0, 0, 1);
}

.circle2 {
  width: 126px;
  height: 126px;
  border: solid 5px rgba(175, 175, 175, 1);
  border-radius: 50%;
  position: relative;
}

.clrcle2_text {
  position: absolute;
  top: 50%;
  left: 50%;
  word-break: keep-all;
  transform: translate(-35px, -42px);
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 20px;
  color: rgba(0, 0, 0, 1);
}

.circle3 {
  width: 126px;
  height: 126px;
  border: solid 5px rgba(175, 175, 175, 1);
  border-radius: 50%;
  position: relative;
}

.c1 {
  height: 8px;
  width: 88px;
  background-color: rgba(255, 177, 0, 1);
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-46px, 14px);
}

.arrow_div_shir {
  margin-top: 41px;
  margin-bottom: 20px;
}

.circle_div {
  text-align: center;
}

.circle_div div {
  display: inline-block;
  margin: 0px 15px;
}

.clrcle3_text {
  position: absolute;
  top: 50%;
  left: 50%;
  word-break: keep-all;
  transform: translate(-35px, -20px);
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 20px;
  color: rgba(0, 0, 0, 1);
}

.serv_india1 {
  text-align: center;
  margin: 50px 0px;
}

.serv_india {
  width: 80%;
}

.bg_clr {
  background-color: rgba(0, 137, 69, 1);
  width: fit-content;
  padding: 3px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 18px !important;
  color: rgba(255, 255, 255, 1);
}

.bg_clr2 {
  font-size: 14px !important;
  letter-spacing: 0;
}

.clr_red {
  color: #b90000;
  font-size: 14px !important;
}

.img_length {
  height: auto;
}

.serv_bar {
  letter-spacing: 3px;

  background-color: rgba(0, 150, 75, 1);
  border-left: 15px solid rgba(0, 132, 66, 1);
  font-weight: bold;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 25px;
  color: white;
  padding: 15px 15px;
  text-align: left;
}

.serv_buttons_container {
  background-color: white;

  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 25px;
  color: white;
  padding: 13px 1px;
  text-align: left;
  margin-top: 1rem;
}

.serv_para_container {
  background-color: rgba(227, 227, 227, 1);
}

.serv_btn {
  background: linear-gradient(to left, #46a0a0 0%, #1c9948 100%);
  width: 25%;
  margin-top: 2rem;
  color: rgba(255, 255, 255, 1);
  border: none;
  padding: 9px 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
  /* margin-left: 11rem; */

  /* background: linear-gradient(to left, #46a0a0 0%, #1c9948 100%);
  width: 25%;
  color: rgba(255, 255, 255, 1);
  border: none;
  padding: 9px 39px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  margin-top: 1rem;

  font-family: "Noto Sans JP Medium"; */
}

.serv_three_btn {
  background-color: rgba(24, 64, 42, 1);
  width: 100%;
  color: rgba(255, 255, 255, 1);
  border: none;
  padding: 19px 19px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  font-family: "Zen Kaku Gothic New", sans-serif;
  border-radius: 30px;
}

.serv_para_cont {
  background-color: white;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 25px;
  color: black;
  padding: 16px 84px;
  text-align: left;
  margin-top: 1rem;
}

i.fas.fa-square-full.serv_fa {
  margin-top: 5px;
  font-size: large;
  font-weight: 600;
  margin-left: 3px;
}

.serv_texthead {
  margin-top: 3rem;
  font-weight: bold;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 20px;
  margin-left: 28px;
}

hr.new4 {
  /* border: 2px solid rgb(33 154 81); */
  opacity: 1;
}

.serv_para {
  width: 100%;
  font-family: "Zen Kaku Gothic New", sans-serif;

  font-size: 14px;
  text-align: left;
  margin-right: 271px;
  line-height: 2;
}

.serv_para_cont .serv_texthead {
  margin-bottom: 10px;
}

.serv_para_cont .serv_para {
  margin-top: 0px;
}

.serv_para_cont .fas.fa-square-full.firstusr_fa {
  font-size: 12px;
  margin-top: 8px;
  margin-left: 0px;
}

.firstusr_para_cont .fas.fa-square-full.firstusr_fa {
  font-size: 12px;
  margin-top: 8px;
  margin-left: 0px;
}

/* ////////////////CSS for first time user PAGE////////////// */
.firstusr_btn div.fltlft {
  padding-left: 40px;
}

.serv_btn div.fltlft {
  padding-left: 40px;
}

.howtouse_btn div.fltlft {
  padding-left: 40px;
}

.faq_btn div.fltlft {
  padding-left: 40px;
}

.os_btn div.fltlft {
  padding-left: 40px;
}

.os_content {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 19px;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  margin: 0px 0px 62px 0px;
  line-height: 2;
}

.sms {
  letter-spacing: 1.3px;
  font-size: 14px !important;
}

.firstusr_container {
  background-color: #ffff;
  margin-top: 2rem;
}

/* .firstusr_three_btn div.fltlft {
    padding-left: 58px;
}
.serv_three_btn  div.fltlft {
    padding-left: 62px;
} */
/* .howtouse_three_btn  div.fltlft {
    padding-left: 62px;
} */
.firstusr_bar {
  letter-spacing: 3px;

  font-weight: bold;
  background-color: rgba(0, 150, 75, 1);
  border-left: 15px solid rgba(0, 132, 66, 1);

  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 25px;
  color: white;
  padding: 15px 15px;
  text-align: left;
}

.firstusr_buttons_container {
  background-color: white;

  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 25px;
  color: white;
  padding: 13px 1px;
  text-align: left;
  margin-top: 1rem;
}

.firstusr_para_container {
  background-color: rgba(227, 227, 227, 1);
}

i.fas.fa-square-full.firstusr_fa {
  color: #1d964b;
}

.firstusr_btn {
  background: linear-gradient(to left, #46a0a0 0%, #1c9948 100%);
  width: 25%;
  margin-top: 2rem;
  color: rgba(255, 255, 255, 1);
  border: none;
  padding: 9px 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;

  /* margin-left: 11rem; */

  /* background: linear-gradient(to left, #46a0a0 0%, #1c9948 100%);
  width: 25%;
  color: rgba(255, 255, 255, 1);
  border: none;
  padding: 9px 39px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  margin-top: 1rem;
  font-family: "Noto Sans JP Medium"; */
}

.firstusr_three_btn {
  background-color: rgba(24, 64, 42, 1);
  width: 100%;
  color: rgba(255, 255, 255, 1);
  border: none;
  padding: 19px 19px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 11px;
  font-weight: 500;
  border-radius: 30px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.firstusr_para_cont {
  background-color: white;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 25px;
  color: black;
  padding: 16px 84px;
  text-align: left;
  margin-top: 1rem;
}

i.fas.fa-square-full.firstusr_fa {
  margin-top: 5px;
  font-size: large;
  font-weight: 600;
  margin-left: 3px;
}

.firstusr_texthead {
  margin-top: 3rem;
  font-weight: bold;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 20px;
  margin-left: 28px;
  margin-bottom: 10px;
}

.landing_container {
  position: unset;
}

hr.new4 {
  /* border: 2px solid rgb(33 154 81); */
  opacity: 1;
}

.firstusr_para {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;

  font-size: 14px;
  text-align: left;
  /* margin-right: 271px; */
}

.howtouse_para_cont .fas.fa-square-full.firstusr_fa {
  font-size: 12px;
  margin-top: 7px;
  margin-left: 0px;
}

/* ////////////////CSS for HowToUse PAGE////////////// */

.howtouse_container {
  background-color: #ffff;
  margin-top: 2rem;
}

.howtouse_bar {
  letter-spacing: 3px;

  background-color: rgba(0, 150, 75, 1);
  border-left: 15px solid rgba(0, 132, 66, 1);
  font-weight: bold;

  font-family: "Noto Sans JP", sans-serif;
  font-size: 25px;
  color: white;
  padding: 15px 15px;
  text-align: left;
}

.howtouse_buttons_container {
  background-color: white;
  width: 100%;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 25px;
  color: white;
  padding: 13px 1px;
  text-align: left;
  margin-top: 1rem;
}

.howtuse_para_container {
  background-color: rgba(227, 227, 227, 1);
}

.howtouse_btn {
  background: linear-gradient(to left, #46a0a0 0%, #1c9948 100%);
  width: 25%;
  margin-top: 2rem;
  color: rgba(255, 255, 255, 1);
  border: none;
  padding: 9px 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;

  font-family: "Zen Kaku Gothic New", sans-serif;
  /* margin-left: 11rem; */

  /* background: linear-gradient(to left, #46a0a0 0%, #1c9948 100%);
  width: 25%;
  color: rgba(255, 255, 255, 1);
  border: none;
  padding: 9px 39px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  margin-top: 1rem;
  font-family: "Noto Sans JP Medium"; */
}

.howtouse_three_btn {
  background-color: rgba(24, 64, 42, 1);
  width: 100%;
  color: rgba(255, 255, 255, 1);
  border: none;
  padding: 19px 19px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  border-radius: 30px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.howtouse_para_cont {
  background-color: white;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 25px;
  color: black;
  padding: 16px 74px;
  text-align: left;
  margin-top: 1rem;
}

i.fas.fa-square-full.howtouse_fa {
  margin-top: 5px;
  font-size: large;
  font-weight: 600;
  margin-left: 3px;
}

.howtouse_texthead {
  margin-top: 3rem;
  font-weight: bold;

  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 20px;
  margin-left: 28px;
  margin-bottom: 10px;
}

hr.new4 {
  /* border: 2px solid rgb(33 154 81); */
  opacity: 1;
}

.howtouse_para {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
  float: left;
  width: 100%;
  font-size: 14px;
  text-align: left;
  margin-right: 271px;
}

.fltrgt {
  float: right;
}

.fltrgttt {
  float: right;
  margin-top: 11px;
}

.fltlft {
  float: left;
  width: 80%;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 16px;
  padding-left: 50px;
}

i.fas.fa-chevron-right {
  margin-top: 0px;
  color: white;
  opacity: 0.7;
}

/* ///////////Thankyou page css/////// */
.thankyou_bar {
  font-weight: bold;

  background-color: rgba(0, 150, 75, 1);
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 25px;
  width: 75%;
  margin: 6px 134px;
  color: white;
  padding: 15px 15px;
  text-align: center;
}

.thanks_para {
  padding: 7px 66px;
  text-align: center;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;

  font-size: 16px;
  line-height: 2em;
  width: 100%;
}

.thankyou_para_cont {
  background-color: white;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 25px;
  color: black;
  padding: 16px 74px;
  text-align: left;
  margin-top: 1rem;
}

.thankyou_container {
  background-color: #ffff;
  margin-top: 2rem;
}

.thankyou_btn {
  background: rgba(0, 0, 0, 1);
  color: rgba(255, 255, 255, 1);
  border: none;
  width: 25%;
  padding: 18px 13px;
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

/* ///////See if it affects other fa fa right//////// */
i.fas.fa-chevron-right {
  margin-top: 8px;
  color: white;
  opacity: 0.7;
}

/* ////////////////// */
/* Member registration page */

.memreg_bar {
  font-weight: bold;

  background-color: rgba(0, 150, 75, 1);
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 25px;
  width: 75%;
  margin: 6px 134px;
  color: white;
  padding: 15px 15px;
  text-align: center;
}

.memreg_para {
  padding: 7px 66px;
  text-align: center;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;

  font-size: 16px;
  line-height: 2em;
  width: 100%;
}

.memreg_para_cont {
  font-weight: bold;

  background-color: white;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 25px;
  color: black;
  padding: 16px 74px;
  text-align: left;
  margin-top: 1rem;
}

.memreg_container {
  background-color: #ffff;
  margin-top: 2rem;
}

.memreg_btn {
  background: rgba(0, 0, 0, 1);
  color: rgba(255, 255, 255, 1);
  border: none;
  width: 25%;
  padding: 18px 13px;
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

/* ///////See if it affects other fa fa right//////// */
i.fas.fa-chevron-right {
  margin-top: 8x;
  color: white;
  opacity: 0.7;
}

/* ////////////////// */
.alert-danger,
.alert-success {
  position: fixed !important;
  bottom: 70px;
  z-index: 9;
  right: 50px;
  box-shadow: 0px 5px 15px rgb(0 0 0 / 60%);
  padding-right: 3rem !important;
  background-color: #000000;
  color: white;
  font-family: "Zen Kaku Gothic New", sans-serif;
  border-radius: 30px;
  padding-left: 2rem !important;
}

.alert-success::before {
  position: absolute;
  content: "\f058";
  font-family: "Font Awesome 5 Free";
  right: 1rem;
  font-size: 22px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 600;
}

.alert-danger::before {
  position: absolute;
  content: "\f057";
  font-family: "Font Awesome 5 Free";
  right: 1rem;
  font-size: 22px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 600;
}

/* login page css */

/* .alert-danger {
  position: fixed;
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
  margin-right: 45rem;
} */

.invalid-feedback {
  display: block !important;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

/* .login_form .invalid-feedback {
  float: left;
  width: 100%;
  margin-top: 10px;
} */
.tester_container {
  border: 1px solid rgba(112, 112, 112, 0.6);
  margin-bottom: 2rem;
  background-color: white;
  padding: 5% 20%;
  border-radius: 10px;
}

input.is-invalid,
textarea.is-invalid {
  background-color: #eaeaea !important;
  padding: 10px 20px;
  outline: none;
  border: 1px solid rgb(220 53 69) !important;
}

.logi_container {
  margin-top: 100px;
  background-color: #ffff;
}

.login_bar {
  height: 71px;
  background-color: rgba(0, 150, 75, 1);
  border-left: 15px solid rgba(0, 132, 66, 1);
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 25px;
  color: white;
  text-align: left;
  padding: 15px 25px;
  font-weight: 500;
  width: 100%;
}

.tester_line {
  background-color: rgba(0, 132, 66, 1);
  height: 67px;
  width: 2%;
}

.signup_btn {
  margin-bottom: 2rem;
  background-color: rgba(0, 165, 83, 1);
  width: 30%;
  color: rgba(255, 255, 255, 1);
  border: none;
  padding: 20px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  margin-top: 40px;
  font-weight: 500;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.login_btn {
  background-color: rgba(0, 0, 0, 1);
  /* width: 25%; */
  color: rgba(255, 255, 255, 1);
  border: none;
  padding: 17px 80px;
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  margin-bottom: 2rem;
  text-align: center;
  margin-top: 33px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.login_logo {
  margin-top: 75px;
  margin-bottom: 55px;
  /* margin-left: 36rem; */
  /* padding: 52px -1px; */
  width: 100%;
  /* float: center; */
  text-align: center;
}

.login_head {
  margin-bottom: 50px;
  font-size: 30px;
  text-align: center;
  font-weight: 800;
}

.algn_centr {
  text-align: center;
}

.algn_centr2 {
  text-align: center;
  margin-top: -43px;
}

.div#document-outline {
  background-color: rgb(255, 255, 255);
}

.whitebg {
  background-color: rgb(255, 255, 255);
  word-break: break-all;
}

.algn_centr1 {
  text-align: center;
  padding: 0% 15%;
}

.logcomp_contnr {
  text-align: center;
  /* margin-top: 2rem;
  margin-left: 10rem; */
}

.login_logo img {
  width: auto;
}

.uidstyl {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 20px;
  width: 100%;
  text-align: right;
  /* margin-right: 42px;
  margin-top: 30px; */
}

.pwdstyl {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 20px;
  width: 100%;
  text-align: right;
  /* margin-right: 42px;
  margin-top: 30px; */
}

.login_lbl {
  /* width: 266px; */
  width: 100%;

  height: 47px;
  background-color: rgba(234, 234, 234, 1);
  border: none;
  padding: 9px 20px;
  margin-bottom: 30px;
  outline: none;
}

.forgtpwdtxt {
  /* margin: 44px 0px; */
  margin-top: 45px;
  color: rgba(0, 128, 204, 1);
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 16px;
  text-decoration: underline;
}

.firsttime {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 30px;
  text-decoration: none !important;
  color: rgba(0, 0, 0, 1);
}

.login_ptxt {
  width: 100%;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 14px;
  color: rgba(112, 112, 112, 1);
  text-align: center;
  /* padding: 32px 21px !important; */
}

/* ///////////Registration confirmation page/////////// */

.regcon_container {
  background-color: #ffff;
}

.regcon_bar {
  background-color: rgba(0, 150, 75, 1);
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 25px;
  color: white;
  text-align: left;
  padding: 15px 25px;
  font-weight: bold;
  width: 100%;
}

.regcon_items_container {
  padding: 0px 87px;
}

.regcon_main_head_txt {
  margin-top: 30px;
  font-size: 20px;
  text-align: left;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.regcon_sub_head_txt {
  font-size: 16px;
  text-align: left;
  color: rgba(112, 112, 112, 1);
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.regcon_lbls {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
}

.regcon_displayed_txt {
  color: rgba(144, 144, 144, 1);
  font-size: 15px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.regcon_det_cont {
  float: left;
  padding: 26px 0px;
  width: 100%;
  text-align: left;
}

.regcon_head1 {
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.regcon_head2 {
  margin-top: 10rem;
  margin-bottom: 2rem;
}

.regcon_head3 {
  margin-top: 10rem;
  margin-bottom: 2rem;
}

.regcon_flex {
  margin-top: 10rem;
}

.regcon_btn {
  background: rgba(0, 0, 0, 1);
  color: rgba(255, 255, 255, 1);
  border: none;
  width: 25%;
  padding: 18px 13px;
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  margin-right: 4rem;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

hr.new1 {
  border: 1px solid #d2d2d2;
  opacity: 1;
}

/* Forgot password page css */

.forgotpwd_input {
  /* width: 266px; */
  width: 100%;
  float: left;
  height: 44px;
  background-color: rgba(234, 234, 234, 1);
  border: none;
  padding: 9px 20px;
  margin-bottom: 21px;
  outline: none;
}

.forgtpwd_container {
  margin-top: 2rem;
  background-color: #ffff;
}

.forgtpwd_contnr {
  text-align: center;
  margin-top: 2rem;
  margin-left: 10rem;
}

.forgtpwd_bar {
  border-left: 15px solid rgba(0, 132, 66, 1);
  background-color: rgba(0, 150, 75, 1);
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 25px;
  color: white;
  text-align: left;
  padding: 15px 25px;
  font-weight: bold;
  width: 100%;
}

.signup_btn {
  margin-bottom: 35;
  background-color: rgba(0, 165, 83, 1);
  width: 24%;
  color: rgba(255, 255, 255, 1);
  border: none;
  padding: 20px 18px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 26px;
  margin-top: 40px;
  font-weight: 500;
}

.forgtpwd_btn {
  background-color: rgba(0, 0, 0, 1);
  width: 25%;
  color: rgba(255, 255, 255, 1);
  border: none;
  padding: 17px 49px;
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  margin-bottom: 2rem;

  text-align: center;
  margin-top: 40px;
  /* font-family: "Noto Sans JP Medium"; */
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.forgtpwd_logo img {
  width: auto;
  /* margin: 0% auto; */
}

.forgtpwd_logo {
  width: 100%;
  /* margin: 49px 557px; */
  float: center;
  align-items: center;
  float: left;
  text-align: center;
  padding-top: 70px;
  padding-bottom: 50px;
}

.forgtpwd_head {
  margin-top: 30px;
  font-size: 30px;
  text-align: center;
}

.forgtpwdcomp_contnr {
  text-align: center;
  margin-top: 2rem;
  margin-left: 10rem;
}

.newpwdstyl {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 20px;
  width: 100%;
  text-align: right;
  /* margin-right: 42px;
  margin-top: 30px; */
}

.confirmpwdstyl {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 20px;
  width: 100%;
  text-align: right;
  /* margin-right: 42px;
  margin-top: 30px; */
}

.forgtpwd_lbl {
  background-color: rgba(234, 234, 234, 1);
  border: none;
  padding: 14px 20px;
  margin-bottom: 12px;
  outline: none;
}

.login_ptxt {
  width: 100%;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 14px;
  color: rgba(112, 112, 112, 1);
  text-align: center;
  /* padding: 32px 374px; */
}

.login_form .invalid-feedback {
  height: 27px;

  float: left;
  width: 100%;
  text-align: left;
}

/* privacy page css */
.main-container {
  padding: 4% 10%;
}

.rectanglepriv {
  background-color: rgba(0, 150, 75, 1);
  border-left: 15px solid rgba(0, 132, 66, 1);
  padding: 2%;
}

.fontclrpriv {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
  font-size: 25px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  text-transform: uppercase;
}

.para1priv {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  padding: 5% 10%;
  text-align: left;
}

/* terms page css */
.rectangleT {
  background-color: rgba(0, 150, 75, 1);
  border-left: 15px solid rgba(0, 132, 66, 1);
  padding: 1%;
  margin-top: 33px;
}

.fontclrT {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
  font-size: 25px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  text-transform: uppercase;
}

.flex {
  display: flex;
  align-items: center;
}

.btmborder {
  border-bottom: 2px solid black;
}

.rightdiv {
  width: 30%;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
  padding-top: 30px;
  padding-bottom: 30px;
}

.leftdiv {
  width: 70%;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
  padding-top: 30px;
  padding-bottom: 30px;
  line-height: 2;
}

.paddingTerm {
  padding: 3% 16% 0%;
  text-align: left;
  word-break: break-all;
}

/* /////////////Article Preview Page //////////////*/

.main_container1.flt {
  background-color: rgba(244, 244, 244, 1);
}

.article_det_img_txt_contnr {
  background-color: rgba(255, 255, 255, 1);
  padding: 2%;
  padding-bottom: 40px;
  margin-bottom: 5rem;
}

.artdet_main_head_font {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
  font-size: 25px;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  text-transform: uppercase;
  word-break: break-all;
}

.artdet_img_container {
  margin-top: 2rem;
}

img.art_det_img {
  outline: none;
}

.artdet_heading__bar {
  background-color: rgba(230, 230, 230, 1);
  width: 100%;
  padding: 7px 11px;
  margin-top: 15px;
  text-align: left;
  font-size: 17px;
  color: rgba(0, 0, 0, 1);
}

.docout-root-wrap li {
  text-align: left;
  position: relative;
  list-style-type: decimal;
}

.artdet_heading__para_cont {
  background-color: rgba(244, 244, 244, 1);
  width: 100%;
  padding: 7px 11px;
  text-align: left;
}

.artdet_shorts_txt_contnr {
  margin: 10px 2px;
}

.artdet_bullet_head_txt {
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
}

.artdet_bullets {
  font-size: 14px;
  padding: 1px 28px;
  color: rgba(0, 0, 0, 1);
}

.artdet_detailed_txt_contnr {
  background-color: rgba(244, 244, 244, 1);
  margin: 0px 0px;
  float: left;
  position: unset !important;
  width: 100%;
}

.artdet_detailed_main_head {
  font-size: 20px;
  color: rgba(0, 0, 0, 1);
  padding: 3px 1px;
}

.docout-root-wrap li ul li {
  list-style-type: disc;
}

.artdet_detailed_main_head2 {
  font-size: 20px;
  color: rgba(0, 0, 0, 1);
  padding: 0px 1px;
  margin-top: 40px;
}

.artdet_detailed_sub_head {
  font-size: 18px;
  margin-top: 18px;

  padding: 5px 1px;
  color: rgba(0, 0, 0, 1);
}

.artdet_detailed_txt_body {
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  padding: 5px 1px;
}

.artdet_btn {
  background: linear-gradient(to left, #46a0a0 0%, #1c9948 100%);
  color: rgba(255, 255, 255, 1);
  border: none;
  width: 40%;
  padding: 11px 10px;
  text-align: center;
  font-size: 15px;
  margin-bottom: 4rem;
  margin-top: 2rem;
  font-weight: 500;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

hr.hrline_artdet {
  height: 2px;
  opacity: 1;
  color: #5f6061;
}

hr:not([size]) {
  height: 2px !important;
  color: #1d964b;
}

.artdet_featrd_img_contnr {
  background-color: rgba(255, 255, 255, 1);
}

.artdet_img_headd {
  font-size: 25px;
  color: rgba(0, 0, 0, 1);
  font-weight: bold;
  font-family: "Zen Kaku Gothic New", sans-serif;
  padding: 5px 26px;
}

.artdet_multi_img {
  padding: 0px 14px;
  margin-top: 3rem;
}

.artdet_img_below_date {
  font-size: 13px;
  color: rgba(162, 162, 162, 1);
  text-align: left;
}

.artdet_img_below_txt {
  display: inline-block;
  width: 319px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  word-break: break-all;
  font-size: 18px;
  color: rgba(0, 0, 0, 1);
  font-weight: bold;
  font-family: "Zen Kaku Gothic New", sans-serif;
  text-align: left;
}

.artdet_date_txt_contnr {
  padding: 0px 14px;
}

.artdet_col2_contnr1 {
  background-color: rgba(244, 244, 244, 1);
  padding: 26px 6px;
  margin-bottom: 3rem;
}

.algn_center {
  margin-bottom: 35px;
}

.gap {
  margin-bottom: 40px;
}

.artdet_col2_contnr2 {
  background-color: rgba(244, 244, 244, 1);
  /* padding: 2%;
  padding-bottom: 40px; */
  margin-bottom: 25px;
}

.artdet_contnr2_txt {
  text-align: center;
  color: rgba(185, 0, 0, 1);
}

.artdet_col2_contnr1_head {
  font-size: 18px;
  color: rgba(0, 0, 0, 1);
  font-weight: bold;
  font-family: "Noto Sans JP";
  padding-bottom: 15px;
  text-align: center;
}

hr.hrstyl {
  height: 1px !important;
  opacity: 0.8;
  color: #a9a49f;
}

.artdet_col2_contnr1 {
  background-color: rgba(255, 255, 255, 1);
  padding: 0px 6px;
  margin-bottom: 25px;
  /* margin-top: 18px; */
}

.artdet_col2_contnr1_date {
  color: rgba(162, 162, 162, 1);
  font-size: 10px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
}

.artdet_col2_contnr1_text {
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
}

/* Column page css */
slide_box .slick-prev {
  height: 140px;
  width: 90px;
  color: transparent;
  border: 0px;
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  background-color: rgba(58, 58, 58, 1) !important;
  outline: none;
  cursor: pointer;
}

.slide_box .slick-prev::before {
  position: absolute;
  content: "\f053";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  color: rgb(255 255 255);
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  line-height: 140px;
  text-align: center;
  font-size: 25px;
  z-index: 9;
}

.slide_box .slick-next {
  height: 140px;
  width: 90px;
  color: transparent;
  border: 0px;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  background-color: rgba(58, 58, 58, 1) !important;
  outline: none;
  cursor: pointer;
}

.slide_box .slick-next::before {
  position: absolute;
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  color: rgb(255 255 255);
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  line-height: 140px;
  text-align: center;
  font-size: 25px;
  z-index: 9;
}

.pagination .react-pagination-js-bootstrap {
  width: 100%;
}

.pagination .react-pagination-js-bootstrap ul {
  display: flex;
}

.slidespace {
  padding: 0% 3%;
}

.pagination {
  padding-left: 0;
  list-style: none;
  text-align: center;
  width: 100%;
  margin-top: 20px;
  text-align: center;
}

.pagination li {
  display: inline-block;
  padding: 10px;
}

/* .paginationdiv.flt {
  /* / width: 84%; / */
/* padding: 3% 39%;
} */
*/

/* ///////////////////////////// */
.column_img_container {
  margin-top: 6rem;
}

.column_det_img_txt_contnr {
  background-color: rgba(244, 244, 244, 1);
  padding: 2%;
  padding-bottom: 40px;
  margin-top: 29px;
  /* margin-bottom: 5rem; */
}

.column_featrd_img_contnr {
  background-color: rgba(255, 255, 255, 1);
}

.column_img_headd {
  margin-left: 27px;
  font-size: 25px;
  color: rgba(0, 0, 0, 1);
  font-weight: bold;
  font-family: "Zen Kaku Gothic New", sans-serif;
  padding: 24px 1px;
}

.column_multi_img {
  padding: 3px 14px;
  /* margin-top: 3rem; */
}

.margin_btm {
  margin-bottom: 5px;
}

.column_img_below_date {
  font-size: 13px;
  color: rgba(162, 162, 162, 1);
  text-align: left;
  margin-top: 11px;
}

.column_img_below_txt {
  word-break: break-all;

  font-size: 18px;
  color: rgba(0, 0, 0, 1);
  font-weight: bold;
  font-family: "Zen Kaku Gothic New", sans-serif;
  text-align: left;
  margin-top: 0.5px;
  display: inline-block;
  width: 357px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.column_date_txt_contnr {
  padding: 2px 14px;
}

.column_rightcont1 {
  margin-bottom: 5px;
}

.column_rightcont1 .col-sm-5 {
  padding-right: 0px;
}

.column_col2_contnr1 {
  background-color: rgba(255, 255, 255, 1);
  padding: 0px 6px;
  margin-bottom: 25px;
  margin-top: 46px;
}

.column_col2_contnr2 {
  background-color: rgba(244, 244, 244, 1);
  padding: 2%;
  padding-bottom: 40px;
  margin-bottom: 3rem;
}

.column_col2_contnr3 {
  background-color: rgba(244, 244, 244, 1);
  padding: 2%;
  padding-bottom: 13px;
  margin-bottom: 3rem;
}

.column_contnr2_txt {
  padding-top: 9rem;
  padding-bottom: 10rem;
  text-align: center;
  color: rgba(185, 0, 0, 1);
}

.column_contnr3_txt {
  padding-top: 9rem;
  padding-bottom: 10rem;
  text-align: center;
  color: rgba(185, 0, 0, 1);
}

.column_col2_contnr1_head {
  font-size: 18px;
  color: rgba(0, 0, 0, 1);
  font-weight: bold;
  font-family: "Zen Kaku Gothic New", sans-serif;
  padding: 19px 0px;
  text-align: center;
}

hr {
  margin: 0px !important;
}

hr.hrstyl {
  height: 1px !important;
  opacity: 0.8;
  color: #a9a49f;
  margin: 18px 0px;
}

.column_col2_contnr1_date {
  color: rgba(162, 162, 162, 1);
  font-size: 10px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
}

.column_col2_contnr1_text {
  font-size: 13px;
  color: rgba(0, 0, 0, 1);
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
  word-break: break-word;
  word-break: break-word;
  display: inline-block;
  width: 150px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.column_mostviewd_img {
  height: auto;
  width: 100%;
}

.paginationdiv.flt {
  width: 100%;
  margin-top: 10px;
  text-align: center;
  /* width: 84%; */
  /* padding: 0px; */
  /* padding: 0px 59px; */
  /* padding: 74px 1px; */
}

a {
  text-decoration: none !important;
}

.para1priv {
  min-height: 35vh;
}

.paddingTerm {
  min-height: 45vh;
}

/* Media query //////////////////////////////////////////////////////////////////////////////////////////*/

/*  */

.slide_box .slick-prev {
  height: 140px;
  width: 90px;
  color: transparent;
  border: 0px;
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  background-color: rgba(58, 58, 58, 1) !important;
  outline: none;
  cursor: pointer;
}

.slide_box .slick-prev::before {
  position: absolute;
  content: "\f053";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  color: rgb(255 255 255);
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  line-height: 140px;
  text-align: center;
  font-size: 25px;
  z-index: 9;
}

.slide_box .slick-next {
  height: 140px;
  width: 90px;
  color: transparent;
  border: 0px;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  background-color: rgba(58, 58, 58, 1) !important;
  outline: none;
  cursor: pointer;
}

.slide_box .slick-next::before {
  position: absolute;
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  color: rgb(255 255 255);
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  line-height: 140px;
  text-align: center;
  font-size: 25px;
  z-index: 9;
}

.pagination .react-pagination-js-bootstrap {
  width: 100%;
}

.pagination .react-pagination-js-bootstrap ul {
  display: flex;
}

.slidespace {
  padding: 0% 3%;
}

.pagination {
  padding-left: 0;
  list-style: none;
  text-align: center;
  width: 100%;
  margin-top: 20px;
  text-align: center;
  display: block !important;
}

.pagination li {
  display: inline-block;
  padding: 10px;
}

/* .paginationdiv.flt {
  /* width: 84%; */
/* padding: 17% 39%; */
/* } */
/* */

a {
  text-decoration: none !important;
}

.japtxt {
  text-align: right;
}

input.is-invalid,
textarea.is-invalid {
  /* width: 139%; */
  width: 100%;

  background-color: #eaeaea !important;
  padding: 10px 8px;
  outline: none;
  border: 1px solid rgb(220 53 69) !important;
}

.column_col2_contnr1 .column_rightcont1:last-child .hrstyl {
  display: none;
}

.artdet_col2_contnr1 .column_rightcont1:last-child .hrstyl {
  display: none;
}

/* home facility start */
.facility_container {
  padding: 85px 0px;
}

.facility_box2 {
  padding-top: 60px;
  padding-bottom: 75px;
}

.facility_box1 {
  background-color: rgba(247, 247, 247, 1);
  padding-top: 30px;
  padding-bottom: 45px;
}

.facility_box3 {
  background-color: rgba(247, 247, 247, 1);
  padding-top: 30px;
  padding-bottom: 45px;
}

.facility_box4 {
  padding-top: 60px;
  padding-bottom: 10px;
}

.facility_box_top {
  display: flex;
  align-items: center;
  padding: 0% 10%;
}

.facility_number {
  font-family: "Lato", sans-serif;
  font-size: 90px;
  color: rgba(0, 165, 83, 1);
  font-weight: bold;
  line-height: 90px;
  letter-spacing: -3px;
}

.facility_arrow {
  margin-left: 22px;
  margin-right: 30px;
}

.facility_arrow i {
  color: rgba(111, 111, 111, 1) !important;
}

.facility_box_head {
  font-size: 22px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 2px;
  line-height: 38px;
  padding-left: 35px;
  position: relative;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.facility_box_head::before {
  position: absolute;
  top: 5px;
  left: 0px;
  content: url("../images/facility_check.png");
}

.facility_cont {
  width: 100%;
}

.facility_box_top {
  position: relative;
}

.facility_box1 .facility_box_top::before {
  position: absolute;
  top: 0%;
  right: 10%;
  content: "";
  background-image: url("../images/facility_icon1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 120px;
  width: 112px;
}

.facility_box2 .facility_box_top::before {
  position: absolute;
  top: 0%;
  right: 15%;
  content: "";
  background-image: url("../images/facility_icon2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 114px;
  width: 108px;
}

.facility_image {
  text-align: center;
  padding: 0% 5%;
}

.facility_box1 .facility_image {
  margin-top: 65px;
}

.facility_box2 .facility_image,
.facility_box3 .facility_image {
  margin-top: 40px;
}

.facility_box_head span {
  font-size: 13px;
  color: rgba(221, 221, 221, 1);
  font-family: "Zen Kaku Gothic New", sans-serif;
  letter-spacing: 1px;
  font-weight: bold;
  margin-left: 10px;
}

.facility_box_tag {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 2px;
}

.facility_image img {
  display: inline-block;
  width: auto;
}

.facility_btn {
  text-align: center;
  margin-top: 20px;
}

.facility_btn button {
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  letter-spacing: 1px;
  background-color: rgba(0, 137, 69, 1);
  border: 1px solid rgba(112, 112, 112, 1);
  border-radius: 30px;
  padding: 7px 50px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.facilityin {
  font-size: 20px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 2px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  width: 440px;
  margin: 0% auto;
  border-bottom: 2px solid rgb(200 200 200);
  padding: 15px 20px;
  margin-top: 20px;
}

.slogan_box {
  background-color: rgba(0, 173, 87, 1);
  padding: 75px 80px;
  text-align: center;
}

.slogan_box img {
  width: auto;
}

.slogan_box {
  display: flex;
  align-items: center;
}

.slogan_left {
  width: 10%;
}

.slogan_right {
  width: 10%;
}

.slogan_middle {
  width: 80%;
}

.slogan_txt {
  font-size: 25px;
  color: rgba(248, 248, 248, 1);
  text-align: center;
  letter-spacing: 2px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  margin-top: 10px;
}

.review_head {
  font-family: "Zen Kaku Gothic New", sans-serif;
  text-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  font-size: 35px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 8px;
  line-height: 35px;
  text-align: center;
  margin-bottom: 70px;
}

.review_container {
  padding: 80px 0px;
}

.review_box {
  padding: 0% 4%;
}

.review_tag {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 25px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 4px;
  text-align: center;
  margin: 12px 0px;
}

.review_desc {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 14px;
  color: rgba(1, 1, 1, 1);
  letter-spacing: 2px;
  font-weight: 300;
}

.review_desc span {
  font-size: 20px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 4px;
  font-weight: 500;
  line-height: 36px;
}

.review_image {
  position: relative;
}

.review_image span {
  position: absolute;
  background-color: rgba(14, 252, 190, 1);
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  font-family: "Zen Kaku Gothic New", sans-serif;
  padding: 30px 10px 10px 10px;
  border-radius: 6px;
  left: 0px;
  top: 0px;
}

.review_image span::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 70%;
  background-color: rgb(255, 255, 255);
  left: 50%;
  top: 13px;
  transform: translateX(-50%);
}

.respond_container {
  padding-bottom: 75px;
}

.respond_main {
  text-align: center;
  padding: 25px 25px 60px 25px;
  background: linear-gradient(
    160deg,
    rgb(247 247 247) 80%,
    rgb(247 247 247 / 78%) 20%
  );
}

.respond_head {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 25px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 3px;
  font-weight: 500;
  margin-bottom: 10px;
}

.respond_cont {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 2px;
  font-weight: 400;
  padding-left: 10%;
}

.respond_btn button {
  border: none;
  font-size: 15px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  letter-spacing: 3px;
  line-height: 20px;
  padding: 15px 30px;
  position: relative;
  margin: 0px 35px;
  width: 50%;
}

.respond_btn button::before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.respond_green {
  background: linear-gradient(to left, #46a0a0 0%, #1c9948 100%);
}

.respond_black {
  background: #000;
  min-height: 70px;
}

.respond_btn {
  display: flex;
  align-items: center;
  padding: 0% 25%;
  margin-top: -35px;
}

.client_logo ul {
  float: left;
  width: 100%;
  padding: 0px;
  margin: 0px;
  text-align: center;
}

.client_logo ul li {
  list-style-type: none;
  width: 20%;
  padding: 15px 2px;
  display: inline-block;
  text-align: center;
}

.client_logo ul li img {
  width: auto;
}

.client_logo {
  padding: 5% 10%;
}

.facility_btn button a {
  color: white;
}

.slogan_box {
  display: block;
  padding: 75px 10px;
}

.slogan_left {
  float: left;
  width: 100%;
}

.slogan_right {
  float: left;
  width: 100%;
}

.slogan_middle {
  float: left;
  width: 100%;
  margin: 4% 0%;
}

.facility_box2 .facility_image img {
  width: 50%;
}

.facilityin {
  width: 100%;
  text-align: center;
}

/* home facility end */
.alter {
  padding-top: 10px;
}
