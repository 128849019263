@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");

.home_banner {
  background-image: url(../../../assets/images/home_banner_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.home_banner_head h1 {
  font-size: 20px;
}

.home_banner .row {
  display: flex;
  align-items: center;
}

.home_banner_logo img {
  width: auto;
}

.home_banner_feat {
  font-family: "Zen Kaku Gothic New", sans-serif;
  margin-top: 10px;
  margin-bottom: 5px;
}

.home_banner_feat .green {
  font-size: 30px;
  color: rgba(0, 137, 69, 1);
  letter-spacing: 4px;
  line-height: 75px;
  background-color: rgb(255, 255, 255);
  /* padding: 0px 25px 0px 10px; */
  font-weight: 700;
  padding: 0px 6px 0px 10px;
}
.home_banner_feat .blue {
  font-size: 30px;
  color: #007e89;
  letter-spacing: 4px;
  line-height: 75px;
  background-color: rgb(255, 255, 255);
  /* padding: 0px 25px 0px 10px; */
  font-weight: 700;
  padding: 0px 6px 0px 10px;
}

.book_man_flex {
  background-color: rgba(0, 150, 75, 1);
  position: relative;
  height: 170px;
  width: 1600px;
  align-items: center;
}

.point_site_logo1 {
  /* height: 76px; */
  height: 76px;
  width: 127px;
  float: right;
}

.home_shir_banner {
  width: auto;
}

.book_man {
  width: 100% !important;
}

.home_banner_feat .orange {
  font-size: 32px;
  color: rgba(255, 177, 0, 1);
  letter-spacing: 4px;
  line-height: 75px;
  background-color: rgb(255, 255, 255);
  padding: 0px 6px 0px 10px;
  font-weight: 700;
}

.home_banner_feat .white {
  font-size: 27px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: 0px;
  line-height: 0px;
  padding: 0px 20px;
}

.home_banner_head {
  font-size: 31px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: 3px;
  line-height: 62px;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 500;
  margin-bottom: 10px;
}

.home_banner_cont {
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: 3px;
  line-height: 30px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 700;
}

.mark_container {
  padding: 60px 0px;
}

.markin {
  padding: 0% 0%;
}

.filler {
  flex-grow: 1;
}

.fillera {
  width: 6%;
}

.homeb {
  width: 70%;
}

.home_mn {
  background-image: url("../../../assets/images/home_man.png");
  background-position: right bottom;
  background-size: contain;
  height: auto;
  width: 100%;
  background-repeat: no-repeat;
  background-color: #369606;
  padding-top: 40px;
  padding-bottom: 40px;
}

.mark_logo {
  text-align: center;
}

.mark_logo img {
  width: auto;
}

.mark_head {
  font-size: 25px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 4px;
  line-height: inherit;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 50px;
  font-weight: 700;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.mark_box_cont {
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 2px;
  line-height: 31px;
  margin-bottom: 64px;
}

.mark_map img {
  width: auto;
  float: right;
}

.mark_map {
  width: fit-content;
  margin-left: 50px;
}

.mark_btn button {
  font-size: 14px;
  text-align: center;
  letter-spacing: 2px;
  line-height: 18px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  border: none;
  width: 252px;
  position: relative;
}

.mark_btn .green {
  background-image: linear-gradient(
    44deg,
    rgba(0, 154, 77, 1) 0%,
    rgba(0, 159, 159, 1) 100%
  );
  color: rgba(255, 255, 255, 1);
  padding: 8px 0px;
}

.mark_btn .black {
  background-color: rgba(46, 46, 46, 1);
  color: rgb(255, 255, 255);
  padding: 18px 0px;
}

.mark_btn button::before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.mark_note {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 2px;
  line-height: 27px;
  text-align: center;
  margin-top: 30px;
  padding: 0% 0%;
}

.mark_note span {
  font-size: 26px;
  color: rgba(0, 173, 87, 1);
  line-height: 34px;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  margin: 0px 5px;
}

.mark_note_box {
  border: 3px solid rgba(217, 217, 217, 1);
  border-radius: 7px;
  padding: 25px;
  position: relative;
  width: max-content;
  font-weight: 700;
}

.mark_trainagle {
  -webkit-text-stroke: 3px #d9d9d9;
  color: transparent;
  font-size: 30px;
  position: absolute;
  top: -28px;
  left: 50%;
  transform: translateX(-50%);
}

.mark_trainagle img {
  width: 70%;
  margin-top: -4px;
}

.point_main {
  padding: 100px 0px;
  background-color: rgba(252, 252, 252, 1);
  margin-top: 100px;
}

.point_title {
  text-align: center;
  padding-top: 20px;
  margin-top: 70px;
  margin-bottom: 70px;
}

.point_title span {
  border: 3px solid rgba(217, 217, 217, 1);
  border-radius: 7px;
  padding: 18px 40px;
  position: relative;
  font-size: 25px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 2px;
  line-height: 35px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 700;
}

.point_title_pic {
  width: auto;
  position: absolute;
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
}

.point_title_arrow {
  width: 30px;
  transform: rotate(180deg);
  position: absolute;
  bottom: -25px;
  left: calc(50% - 15px);
}

.point_box {
  border: 7px solid rgba(232, 232, 232, 1);
  border-radius: 14px;
  padding-top: 65px;
  padding-bottom: 25px;
  margin-top: 75px;
  position: relative;
}

.point_box_head {
  font-family: "Oswald", sans-serif;
  font-size: 83px;
  font-weight: 700;
  color: rgba(0, 150, 75, 1);
  letter-spacing: -3px;
  line-height: 0;
  display: flex;
  align-items: center;
  margin-top: -100px !important;
  background-color: rgb(255, 255, 255);
  width: 200px;
  margin: 0% auto;
}

.point_box_head span {
  font-size: 39px;
  line-height: 55px;
  border-top: 5px solid rgba(0, 150, 75, 1);
  border-bottom: 5px solid rgba(0, 150, 75, 1);
  margin-right: 5px;
}

.point_box_head b {
  line-height: 46px;
}

.point_tag {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 23px;
  text-align: center;
  letter-spacing: 2px;
  line-height: 33px;
  color: rgba(24, 64, 42, 1);
  margin-top: 40px;
  margin-bottom: 30px;
  font-weight: 700;
}

.point_tag span {
  color: rgba(255, 177, 0, 1);
  font-weight: 700;
}

.point_image {
  text-align: center;
}

.point_image img {
  width: auto;
}

.point_image1 img {
  width: 100%;
  padding: 0px 50px;
}

.point_cont {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  letter-spacing: 1px;
  line-height: 22px;
  margin-bottom: 22px;
}
.point_cont1 {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  letter-spacing: 1px;
  line-height: 22px;
  margin-bottom: 22px;
}

.point_box_btn {
  width: 228px;
  background-color: rgba(0, 154, 77, 1);
  border: none;
  padding: 16px 0px;
  position: absolute;
  left: 50%;
  bottom: -28px;
  transform: translateX(-50%);
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  letter-spacing: 1px;
  line-height: 18px;
}

.point_box2 {
  padding-top: 50px;
  padding-bottom: 55px;
}

.point_box2 .point_tag {
  margin-top: 30px;
  margin-bottom: 10px;
}

.point_box_btn::before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.point_box2 .point_box_head {
  margin-top: -90px !important;
}

.point_desc {
  display: flex;
  align-items: center;
}

.point_desc_pic img {
  width: 104px;
  height: 104px;
}

.point_desc_txt {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 15px;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  letter-spacing: 2px;
  line-height: 18px;
  background-color: rgba(255, 255, 255, 1);
  border: 2px solid rgba(242, 242, 242, 1);
  border-radius: 7px;
  padding: 8px 37px;
  position: relative;
  margin-right: 20px;
  width: 235px;
  font-weight: 700;
}

.point_time {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
  font-size: 28px;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  letter-spacing: 2px;
  line-height: 36px;
  margin: 5px 0px;
}

.point_desc_arrow {
  position: absolute;
  width: 20px;
  right: -19px;
  transform: rotate(90deg);
  top: calc(50% - 10px);
}

.point_box_money {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 17px;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  letter-spacing: 1px;
  background-color: rgba(255, 255, 255, 1);
  border: 2px solid rgba(242, 242, 242, 1);
  border-radius: 7px;
  margin-bottom: 22px;
  padding: 15px 0px;
  font-weight: 700;
}

.point_box_money span {
  font-family: "Oswald", sans-serif;
  font-size: 58px;
  color: rgba(0, 150, 75, 1);
  letter-spacing: -6px;
  font-weight: 700;
  line-height: 58px;
}

.point_box4 {
  padding-right: 15%;
  padding-left: 15%;
}

.point_box3 .point_tag,
.point_box4 .point_tag {
  margin-top: 30px;
  margin-bottom: 20px;
}

.point_site {
  background-color: rgba(0, 150, 75, 1);
}

.point_site_logo img {
  width: auto;
}

.point_site_txt {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 19px;
  color: rgba(248, 248, 248, 1);
  letter-spacing: 2px;
  margin-left: 10px;
  font-weight: 700;
}

.point_site_txt span {
  font-size: 27px;
}

.point_sitein {
  display: flex;
  align-items: center;
  position: relative;
  padding: 45px 90px;
}

.point_site_man {
  height: 290px;
  width: 248px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(276px, -205px);
}

.point_caese_tag {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 400;
  font-size: 25px;
  color: rgba(24, 64, 42, 1);
  letter-spacing: 4px;
  line-height: 35px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 40px;
}

.point_case_logo {
  text-align: center;
}

.point_case_logo img {
  width: auto;
}

.point_case_main {
  padding: 70px 0px;
}

.point_case_cont {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 2px;
  line-height: 18px;
}

.point_case_line1 {
  font-size: 17px;
}

.point_case_line2 {
  font-size: 15px;
  margin: 7px 0px;
}

.point_case_line3 {
  font-weight: 400;
  font-size: 14px;
}

.point_case_image {
  margin: 10px 0px;
}

.point_case_name {
  font-family: "Oswald", sans-serif;
  font-size: 20px;
  color: rgba(0, 150, 75, 1);
  letter-spacing: 0px;
  font-weight: 500;
}

.partner_container {
  background-color: rgba(252, 252, 252, 1);
  padding: 56px 0px;
}

.partner_tag {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 400;
  font-size: 25px;
  color: rgba(24, 64, 42, 1);
  letter-spacing: 4px;
  line-height: 35px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 50px;
}

.partner_logo {
  text-align: center;
}

.partner_logo img {
  width: auto;
}

.partner_images {
  padding: 0% 10%;
}

.partner_images ul {
  padding: 0px;
  margin: 0px;
  text-align: center;
}

.partner_images ul li {
  float: none;
  width: 20%;
  display: inline-block;
  text-align: center;
  margin-bottom: 50px;
}

.partner_images ul li img {
  width: auto;
}

.partner_btn {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 13%;
}

.partner_btn button {
  background-image: linear-gradient(
    44deg,
    rgba(0, 154, 77, 1) 0%,
    rgba(0, 159, 159, 1) 100%
  );
  border: none;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 15px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  letter-spacing: 4px;
  line-height: 20px;
  padding: 15px 75px;
  position: relative;
}

.partner_btn button::before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.col_space {
  padding: 0px 110px;
}

.price-img {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 0px;
}
.home_shir_banner1 {
  width: 70%;
}
.fltlft1 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.special {
  max-width: 90% !important;
}
.kv-image {
  padding-top: 35px;
}
.reservation-btn {
  bottom: 120px;
  right: 10px;
  display: block;
  width: 300px;
  position: fixed;
}
.reservation-btn1 {
  bottom: 65px;
  right: 10px;
  display: block;
  width: 300px;
  position: fixed;
}
.reservation-btn2 {
  bottom: 10px;
  right: 10px;
  display: block;
  width: 300px;
  position: fixed;
}
p {
  margin-bottom: 0px;
}
.service_bar1 {
  margin-bottom: 20px;
}
.service_bar2 {
  margin-bottom: 0px;
}
.row .container {
  margin: 0 auto;
  max-width: 1320px;
}

.mark_head1 {
  font-size: 56px;
  color: black;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: 800;
  font-family: "Zen Kaku Gothic New", sans-serif;
}
.sub_title {
  color: #000;
  font-size: 20px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  text-align: center;
  line-height: 30px;
  padding-top: 25px;
  padding-bottom: 25px;
  font-weight: 500;
}
.sub_title1 {
  color: #000;
  font-size: 20px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  text-align: center;
  line-height: 30px;
  padding-top: 10px;
  padding-bottom: 25px;
  font-weight: 500;
}
.sub_title1::before {
  content: url("../../../assets/images/profile-icon.png");
  position: relative; /*or absolute*/
  z-index: 100000; /*a number that's more than the modal box*/
  left: -10px;
  top: 15px;
}
.span-title {
  color: #585858;
  font-size: 14px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  text-align: center;
}
.pervice-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 96%;
  padding-top: 30px;
}
.extra {
  padding-top: 0px !important;
}
.pervice-img1 {
  display: block;
  /* margin-left: auto; */
  margin-right: auto;
  width: 96%;
  padding-top: 78px;
}
.os_content1 {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 14px;
  color: #000;
  text-align: left;
  margin: 40px 0 50px;
  line-height: 30px;
}
.os_content {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 18px;
  color: #000;
  text-align: center;
  margin: 40px 0 50px;
  line-height: 30px;
}
.price-img {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 0px;
}

.point_cont1 {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  letter-spacing: 1px;
  line-height: 22px;
  margin-bottom: 22px;
}
.point_title span {
  border: 3px solid rgba(217, 217, 217, 1);
  border-radius: 7px;
  padding: 18px 40px;
  position: relative;
  font-size: 25px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 2px;
  line-height: 35px;
  font-family: "Noto Sans JP", sans-serif;
}
.os_content1 {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 14px;
  color: #808080;
  text-align: left;
  margin: 40px 0 50px;
  line-height: 30px;
}
.img_length1 {
  width: 20%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}
.img_length2 {
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.os_content3 {
  font-size: 14px;
  font-family: "Zen kaku gothic new", sans-serif;
  line-height: 22px;
  text-align: left;
  color: black;
  width: 70%;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
}
.mark_logo1 img {
  width: 38%;
  margin-top: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.line {
  width: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.line1 {
  width: auto;
  display: block;
  /* margin-left: auto; */
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
.main_title {
  font-size: 49px;
  text-align: center;
  font-family: "Zen Kaku Gothic New", sans-serif;
  color: BLACK;
  font-weight: 700;
  margin-top: 80px;
}
.main_sub_t {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  line-height: 26px;
  color: black;
  font-family: "Zen Kaku Gothic New", sans-serif;
}
.background {
  background-color: #d8d8d8;
  padding-bottom: 80px;
}
.background-g {
  background-color: #00964b;
  padding-bottom: 80px;
}
.background-b {
  background-color: black;
  padding-bottom: 50px;
  padding-top: 50px;
}
.imagebg {
  background-image: url(../../../assets/images/ScrollGroup1.png);
  background-position: bottom 0px right 20px;
  background-repeat: no-repeat;
  background-size: 20%;
}
.imagebg2 {
  background-image: url(../../../assets/images/ScrollGroup2.png);
  background-position: bottom 0px right 20px;
  background-repeat: no-repeat;
  background-size: contain;
}
.background-w {
  background-color: #ffffff;
  padding-bottom: 80px;
  padding-top: 80px;
}
.background-y {
  background-color: #f8f8f8;
  padding-bottom: 80px;
}
.background-l {
  background-color: #efefef;
  padding-bottom: 80px;
  padding-top: 80px;
}
.img_length3 {
  width: 30%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 90px;
  padding-top: 40px;
}
.img_length4 {
  width: 70%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
.img_length5 {
  width: 20%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
.img_length6 {
  width: 60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 0px;
}
.service {
  margin-top: 20px !important;
  margin-bottom: 0px;
}
.descp {
  font-size: 16px;
  line-height: 24px;
  color: black;
  font-weight: 700;
  padding: 30px 0px 30px 0px;
}
.span-text {
  text-decoration: underline;
  border: none;
  border-radius: 0px;
  padding: 0px;
  position: relative;
  font-size: 16px;
  color: #000;
  letter-spacing: 0px;
  line-height: 20px;
  font-weight: 700;
}
.white-color {
  color: white !important;
}
.work-title {
  letter-spacing: 0.38px;
  text-align: left;
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 700;
  padding-top: 8px;
}
.span-text {
  text-decoration: underline;
  border: none !important;
  border-radius: 0px !important;
  padding: 0px !important;
  position: relative !important;
  font-size: 16px !important;
  color: #000 !important;
  letter-spacing: 0px !important;
  line-height: 20px !important;
  font-weight: 700 !important;
}
/* .main_sub_t:before {
  position: absolute;
  content: "";
  width: 34px;
  height: 1px;
  background-color: #707070;
  bottom: -44px;
  display: inline;

  left: 50%;
  transform: translate(-50%, -25px);
} */

.j-title {
  font-family: "Zen kaku gothic new", sans-serif;
  font-size: 20px;
  font-weight: 800;
  text-align: left;
}

.m-subtitle {
  font-family: "Zen kaku gothic new", sans-serif;
  font-size: 27px;
  font-weight: 800;
  text-align: left;
  padding-top: 6px;
  color: white;
}
.m-subtitle span {
  background-color: #009a4d;
  padding-right: 5px;
  padding-left: 5px;
}
.m-descp {
  font-family: "Zen kaku gothic new", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  padding-top: 40px;
}
.special1 {
  max-width: 1000px;
  padding-top: 60px;
}
.special2 {
  max-width: 800px;
}
.m-title {
  font-size: 49px;
  font-style: italic;
  color: #009a4d;
  font-family: "Poppins", sans-serif;
  text-align: left;
}

.m-title1 {
  font-size: 49px;
  font-style: italic;
  color: #009a4d;
  font-family: "Poppins", sans-serif;
  text-align: left;
}

.m-title2 {
  font-size: 49px;
  font-style: italic;
  color: #009a4d;
  font-family: "Poppins", sans-serif;
  text-align: left;
}

.contact {
  min-height: 160px;
  padding-top: 40px;
  padding-bottom: 40px;
  background-image: url(../../../assets/images/contact-bg.png); /* 背景画像指定 */
  background-repeat: no-repeat; /* 背景の繰り返し設定 */
  background-position: center center;
}
.avii {
  min-height: 55vh;
  background-image: url(../../../assets/images/avii.png) !important; /* 背景画像指定 */
  background-repeat: no-repeat; /* 背景の繰り返し設定 */
  background-position: center center;
  margin-top: -53px;
  background-size: cover;
}
.avii h2 {
  font-size: 28px;
  font-family: "Zen kaku gothic new", sans-serif;
  color: white;
  text-align: center;
  font-size: 700;
}
.avii p {
  font-size: 18px;
  line-height: 28px;
  font-family: "Zen kaku gothic new", sans-serif;
  text-align: center;
  color: white;
  font-weight: 700;
}
.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.con-title {
  font-size: 49px;
  color: white;
  font-style: italic;
  font-family: "Poppins", sans-serif;
  text-align: left;
  font-weight: 700;
}
.con-sub {
  font-size: 18px;
  color: white;
  text-align: left;
  font-family: "Zen kaku gothic new", sans-serif;
  font-weight: 700;
}
.con-sub span {
  font-size: 32px;
  color: white;
  text-align: left;
  text-decoration: underline;
  font-family: "Zen kaku gothic new", sans-serif;
}
/* .mark_head1:before {
  content: "";
  display: inline-block;
  width: 28px;
  height: 28px;
  background-image: url(../../../assets/images/mypage.png);
  background-size: contain;
  vertical-align: middle;
  margin-right: 10px;
} */
.margin {
  margin-top: -35px !important;
}
.con-sub span a {
  color: white;
}
.con-sub span a:hover {
  color: white;
}
.greenTitle {
  color: #009a4d;
  font-size: 56px;
  line-height: 60px;
  font-weight: 600;
  font-family: "Zen kaku gothic new", sans-serif;
  text-align: center;
}
.underlinetext {
  font-size: 15px;
  font-weight: 600;
  font-family: "Zen kaku gothic new", sans-serif;
  color: black;
  text-align: center;
}
.underlinetext span {
  text-decoration: underline;
}
.img_length8 {
  width: 30%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0px;
  padding-top: 40px;
}
.bg-img {
  background-image: url(../../../assets/images/Ellipse59.png); /* 背景画像指定 */
  background-repeat: no-repeat; /* 背景の繰り返し設定 */
  background-position: center center;
  background-size: auto;
}
.spacing {
  padding-bottom: 60px;
}
.reservation-btn {
  bottom: 120px;
  right: 10px;
  display: block;
  width: 300px;
  position: fixed;
}
.reservation-btn1 {
  bottom: 65px;
  right: 10px;
  display: block;
  width: 300px;
  position: fixed;
}
.reservation-btn2 {
  bottom: 10px;
  right: 10px;
  display: block;
  width: 300px;
  position: fixed;
}
.button {
  border: none;
  color: white;
  padding: 10px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.button1 {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  border: 1px solid #ffffff;
  display: block;
  margin: 0 auto;
  margin-top: 30px;
  font-family: "Zen kaku gothic new", sans-serif;
  font-size: 18px;
}

.button1:hover {
  background-color: #ffffff;
  color: #000;
}
