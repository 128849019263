.flt {
  float: left;
  width: 100%;
}
.flex {
  display: flex;
}
.main-containerC {
  padding: 4% 0%;
}
.contact_content {
  text-align: center;
  margin-left: 10px;
}
.contact_space {
  padding: 3% 7% 0% 7%;
}
.contact_paragraph .col-sm-8 {
  width: 75%;
}
.rectangleCont {
  background-color: rgba(0, 150, 75, 1);
  border-left: 15px solid rgba(0, 132, 66, 1);
  padding: 1%;
}
.fontclrCont {
  letter-spacing: 3px;

  font-weight: bold;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 500;
  font-size: 25px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  text-transform: uppercase;
}
.contact_paragraph {
  /* padding: 13% 16%; */
  color: rgba(0, 0, 0, 1);
  font-family: "Zen Kaku Gothic New", sans-serif;
  text-align: center;
}
.contact_divclr {
  width: 100%;
  padding: 10% 26% 10% 38%;
}
.contact_checkbox {
  text-align: left;
  padding: 1% 14%;
}
.btnsty_contact {
  background-color: black;
  text-align: center;
  color: white;
  padding: 2% 1% 2% 10%;
}
.Ldiv {
  color: rgba(0, 0, 0, 1);
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 900;
  font-size: 50px;
  line-height: 60px;
}
/* .line{
   line-height: 54px; 
} */

.button-icon {
  margin-right: 5px;
  border: none;
  border-radius: 100%;
  background-color: #000;
  padding: 10px 15px 5px;
}
.number {
  padding-left: 0% 24%;
}
.text1 {
  background-color: black;
  color: white;
  padding: 7px;
}
.text2 {
  padding: 7px;
}
.bgclr {
  padding: 0% 24%;
}
.contact-icon {
  color: #fff;
  font-size: 25px;
  text-align: center;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  align-items: top;
  margin: 0px;
  padding: 0px;
  margin-bottom: 5px;
}
.inputdesign {
  padding: 10px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 18px;
  color: rgba(193, 189, 189, 1);
  background-color: rgba(255, 255, 255, 1);
}
input {
  outline: none;
  border: 1px solid rgba(192, 189, 189, 1);
}

.labeldesign {
  color: rgba(0, 0, 0, 1);
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 20px;
  text-align: left;
}
.label2design {
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgba(217, 0, 0, 1);
  font-size: 11px;
  color: rgba(255, 255, 255, 1);
  margin-left: 15px;
}
.examp {
  /* text-align:center; */
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
  font-weight: 500;
  padding-left: 26%;
}
.clr {
  color: rgba(0, 129, 239, 1);
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 16px;
  font-weight: 500;
}
.star {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  font-weight: 500;
}
.line3 {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  font-weight: 500;
}
.contact_text {
  background-color: rgba(225, 225, 225, 1);
  padding: 25px 0px;
}
.checklbl {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 18px;
  color: rgba(0, 0, 0, 1);
  margin-left: 6px;
}
.form-group {
  margin-top: 6%;
}
.arrowcont {
  float: right;
}
.namepadding {
  padding: 16px 0px;
  text-align: left;
}
.namepadding_top {
  padding-bottom: 61px;
  padding-top: 0px;
  text-align: left;
}
.namepadding_bottom {
  padding: 10px 0px;
  text-align: left;
}
.agrmnt {
  padding-top: 9%;
  padding-left: 26%;
}
.btnsty {
  background-color: black;
  text-align: center;
  color: white;
  padding: 2% 10%;
}
.bt {
  padding: 6% 0%;
  text-align: left;
}
.span1 {
  display: flex;
}
.span2 {
  display: flex;
}
.cont_box {
  display: flex;
  padding: 3% 20%;
}

i.fas.fa-chevron-right {
  padding: 0px;
}
.disable {
  pointer-events: none;
  opacity: 0.6;
}
.namepadding input {
  background: #fff !important;
  width: 100%;
}
.namepadding textarea {
  background: #fff !important;
  width: 100%;
}
.namepadding input,
.namepadding textarea {
  padding: 10px;
  background: #fff !important;
  width: 100%;
}
textarea {
  outline: none;
  border: 1px solid rgba(192, 189, 189, 1);
}
.contactInquiry1 {
  padding: 0px 214px;
}
.contactInquiry {
  float: left;
  width: 100%;
  background-color: rgba(0, 154, 77, 1);
  /* padding: 162px 0px; */
  padding: 0px 174px;
  position: relative;
}
.contactBackground {
  background-image: url("../../../assets/images/contactBackground.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.imgsize {
  width: fit-content;
  float: right;
}
.imgsize1 {
  position: absolute;
  width: fit-content;
}
.contactText {
  font-size: 35px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  color: rgba(225, 225, 225, 1);
}
ul.conrad {
  padding-left: 0em;
}

.conrad li {
  list-style-type: none;
  margin-bottom: 6px;
}
.conrad li input {
  width: auto;
  margin-right: 10px;
}

@media (min-width: 320px) and (max-width: 767px) {
  .contact_paragraph .col-sm-8 {
    width: 100%;
  }
  .main-containerC {
    padding: 4% 0%;
  }
  .contact_paragraph {
    padding: 13% 0%;
    font-size: 7px;
  }
  .labeldesign {
    font-size: 10px;
    line-height: 4;
  }
  .bgclr {
    padding: 4% 8%;
  }
  .Ldiv {
    font-size: 25px;
  }
  /* .cont_box{
  display:block;
} */
  /* .labeldesign {
  width: 100%;
  font-size: 10px;
}
.span1{
  margin-bottom: 6px;
} */
  /* .text1{
  padding:0%;
} */
  .fontclrCont {
    font-size: 18px;
  }
  .line3 {
    font-size: 10px !important;
  }
  .examp {
    font-size: 10px;
  }
  .clr {
    font-size: 10px !important;
  }
  .inputdesign {
    font-size: 10px !important;
  }
  .checklbl {
    font-size: 10px !important;
  }
  .cont_box {
    padding: 3% 13%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .cont_box {
    padding: 3% 16% 3% 23%;
  }
  .contact_paragraph {
    padding: 13% 0%;
  }
  .Ldiv {
    font-size: 46px;
  }
  .bgclr {
    padding: 0% 8%;
  }
}
input {
  outline: none;
}
@media (min-width: 2048px) {
  .cont_box {
    padding: 3% 22% 3% 26%;
  }
}
