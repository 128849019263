.flt {
  float: left;
  width: 100%;
}
.main_containers_contact {
  text-align: center;
  margin-top: 150px;
}
.thankyou_bar_contact {
  font-weight: bold;
  background-color: rgba(0, 150, 75, 1);
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 25px;
  width: 75%;
  /* margin: 6px 134px; */
  color: white;
  padding: 15px 15px;
  text-align: center;
}
.thankyou_para_Contact {
  background-color: white;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 25px;
  color: black;
  padding: 40px 74px 100px;
  text-align: left;
  /* margin-top: 1rem; */
}
