.service_bar {
  margin-bottom: 53px;
}
.row .container {
  margin: 0 auto;
  max-width: 1320px;
}

.mark_head {
  font-size: 40px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 4px;
  line-height: inherit;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 50px;
  font-weight: 800;
  font-family: "Noto Sans JP", sans-serif;
}

.service-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.os_content1 {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 14px;
  color: #000;
  text-align: left;
  margin: 40px 0 50px;
  line-height: 30px;
}
.os_content {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 18px;
  color: #000;
  text-align: center;
  margin: 40px 0 50px;
  line-height: 30px;
}
.price-img{
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
    margin-bottom: 0px;
}

.point_cont1 {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  letter-spacing: 1px;
  line-height: 22px;
  margin-bottom: 22px
}
