.image_display {
  width: 100%;
  float: left;
}
.alias {
  cursor: pointer;
}
.filebackground {
  background-color: rgb(228, 228, 228);
  padding: 0px 0px !important;
  border-radius: 50%;
  /* height: 70px; */
  /* width: 70px; */
  position: relative;
  text-align: center;
  /* margin-left: 25px; */
  /* margin-right: 25px;*/
}
.qqq {
  pointer-events: none;
  /* margin-top: 10px; */
}
.upbtn {
  width: 20%;
  float: left;
  text-align: center;
  margin-left: 19px;
}
.file_plus_ico {
  font-size: 100px;
  font-weight: 300;
  position: absolute;
  left: 0px;
  top: 3px;
  height: 100%;
  width: 100%;
  display: block;
  line-height: 60px;
}
.file_plus_ico1 {
  font-size: 100px;
  font-weight: 300;
  position: absolute;
  left: 0px;
  top: 3px;
  height: 100%;
  width: 100%;
  display: block;
  line-height: 60px;
  opacity: 0.4;
  pointer-events: none;
}
.inputUpload {
  position: justify;
  cursor: pointer;
  /* opacity: 0; */
  width: 100%;
  height: 100%;
  border: none;
  outline: 0;
  position: absolute;
  left: 0px;
  top: 3px;
}
/* .button_img_div:focus{
  border:2px solid yellow;
} */
.button_img_div {
  width: 100%;
  float: left;
  margin: 5px 0px 11px;
  display: flex;
  align-items: center;
  /* padding:0px 79px 9.5px 88px;
    text-align: center; */
}
.button_img_div1 {
  width: 100%;
  float: left;
  padding: 1% 0% 1% 0% !important;
  text-align: center;
  margin-left: 0px;
  margin: 5px 0px 7px;
}
.imgdisable1 {
  float: left;
  width: 12%;
}
.comment_icon_div {
  /* align-items: flex-start !important; */
  padding-left: 0px;
  width: 10%;
  margin-top: -4px;
}
.imgtag_hr {
  color: rgb(0, 0, 0) !important;
}
.imgtagcomment_hr {
  color: rgb(228 228 228) !important;
  display: none;
}
.all_comment {
  padding: 0px;
  margin-bottom: 0px;
}
.all_comment li {
  list-style-type: disc;
  font-size: 12px;
}
.delete_img {
  color: rgb(255, 255, 255) !important;
  margin-top: 2px;
  margin-right: 5px;
}
.simpleButton i {
  margin-top: 0px;
  margin-right: 1px;
  color: #fff;
}
.name_div {
  float: left;
  width: 50%;
  text-align: left;
  /* display: inline-block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis; */
  margin-bottom: -20px;
}
.nameEdit_div {
  color: rgb(0, 0, 0);
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.imgedit_div {
  float: right;

  text-align: right;
  margin-bottom: 22px;
  margin-top: -43px;
  width: 43%;
}

.imgedit_div1 {
  float: right;
  width: 39%;
  text-align: right;
  margin-top: -5px;
  /* margin-bottom: 20px; */
  opacity: 0.4;
  pointer-events: none;
}
.img_link {
  background-color: bisque;
  padding: 5px;
  font-size: 13px;
}
.copy_link {
  font-size: 15px;
}
.edit_icon {
  color: rgb(255, 255, 255) !important;
  /* padding: 3px 0px 3px 10px; */
}

.test {
  border: 2px solid red;
  height: 104px;
}

.best {
  border: none;
  height: 104px;
}

.abc {
  display: flex;
  width: 88%;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: -4px !important;
  background: rgb(228 228 228);
  border-radius: 10px;
}
.abc::-webkit-scrollbar {
  background-color: rgb(255 255 255);

  margin-top: 50px;
  height: 13px;
  border: 5px solid white;
}
.abc::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 0px rgb(0 0 0 / 0%);

  background-color: rgba(0, 165, 83, 1);
  border: 2px solid white;

  /* height: 50px; */
}
.abc::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgb(0 0 0 / 0%);
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  height: 50px;
  padding-top: 3px;
}
.selectedImage {
  border: 5px solid yellow;
  /* border-color: yellow; */
}
.delete_btn {
  width: 82px;
  float: right;
  background-color: rgba(0, 165, 83, 1);
  border: none;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  padding: 7px;
  border-radius: 2px;
  margin-left: 10px;
  margin-right: 16px;
}
.upload_icon {
  font-size: 50px;
  color: rgba(0, 165, 83, 1);
  margin-left: -33px;
  margin-bottom: -9px;
}
.comment_ico {
  margin-right: 22px;
  font-size: 17px;
}
.react-confirm-alert-overlay {
  z-index: 999999 !important;
}
/* .divflex{
  flex-grow: 1;
} */
/* .flt{
  float:left;
  width: 100%;
} */
.flex {
  display: flex;
}
.image_length {
  color: red;
  font-size: 8px;
  margin-top: 98px;
}
.imgdisable {
  opacity: 0.4;
  pointer-events: none;
  float: left;
  width: 12%;
}
.selectedImg_div {
  position: relative;
  width: 100%;
  height: 100%;
}
.selectedImg_div1 {
  position: relative;
  width: 16vw;
  height: 16vh;
  border: 2px solid black;
}
.comment_header {
  font-size: 22px;
  color: rgb(0 0 0);
}
.commentSpan {
  word-break: break-word;
  float: left;
  width: 100%;
  font-size: 17px;
  padding-bottom: 5px;
}
.upload_btn {
  width: 90px !important;
  background-color: rgba(0, 165, 83, 1);
  border: none;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 14spx !important;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  padding: 10px;
  margin-left: 10px;
}
.commentTime {
  color: #8f8380 !important;
  margin-left: 26px;
}
.main_image {
  width: 100%;
  /* margin-left: 10px; */
  max-width: 100%;
  display: flex;
  /* background: rgb(228 228 228); */
  padding: 10px 0px 10px 0px;
  border-radius: 10px;
  height: 150px;
}
.headerSpan {
  font-size: 1em;
  font-weight: 800;
  color: rgb(0 0 0);
  font-family: "Zen Kaku Gothic New", sans-serif;
}
.commentBody {
  display: flex;
  margin-top: 2px;
}

.simpleButton {
  width: 73px;
  background-color: rgba(0, 165, 83, 1);
  border: none;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  padding: 7px;
  margin-right: 2px;
  border-radius: 2px;
  visibility: hidden;
}
.main {
  width: 25%;
  position: absolute;
  overflow-x: auto;
  background: rgb(228 228 228);
  text-align: center;
  border-radius: 10px;
  z-index: 1;
  padding: 3px;
  right: 0;
  opacity: 1;
  transition: all 0.8s ease;
  margin-right: 20px;
  height: auto !important;
  max-height: 100%;
  margin-top: 10px;
  font-family: "Zen Kaku Gothic New", sans-serif;
}
.commentSpan1 {
  word-break: break-all;
  float: left;
  width: 100%;
  font-size: 17px;
}
.spanTime1 {
  color: rgb(95 93 93);
  /* margin-left: 38px; */
}
.commentDelete {
  color: #000000;
  text-align: right;
  float: right;
  position: absolute;
  top: -1px;
  right: 9px;
}
.imageheader_main {
  width: 100%;
  float: left;
}
.delete_comment_icon {
  font-weight: 800 !important;
  color: black;
}
.gallary {
  width: 70%;
  float: left;
}
.comment {
  width: 30%;
  float: left;
}
.image-height {
  /* width: 15vw; */
  height: 12vh;
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0);
  margin: 10px 0px;
  outline: 0px;
  border: 0px;
}
.image-padding {
  width: 100%;
  padding-left: 150px;
}
.imgedit_div button i {
  float: none;
  margin: 0px 5px 0px 0px;
}
.sap {
  position: relative;
}

/* comment box new css start*/
.img_display {
  position: relative;
}
.comment_box {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 999;
}
/* comment box new css end*/

@media (max-width: 767px) {
  /* .main{
       width: 32vw;
      top: 4vh;
      position: absolute;
      overflow-x:auto;
      overflow-y: hidden;
      background:rgb(228 228 228);
      text-align: center;
      border-radius: 10px;
      z-index: 1;
      padding: 3px;
      right: 0px;
      opacity: 1;
      transition: all .8s ease;
      height: 100% !important;
      margin-right: 0px;
       height: auto !important;
      max-height: 57%;
      overflow: auto;
  
} */
}

@media (min-width: 768px) and (max-width: 991px) {
  .test {
    border: 2px solid red;
    height: 77px;
  }

  .best {
    border: none;
    height: 77px;
  }
  .main {
    margin-right: 0px;
  }
  .upload_icon {
    margin-left: -10px;
    margin-bottom: 12px;
    font-size: 35px;
  }
  .image-padding {
    padding: 0px 49px;
  }
  /* .selectedImg_div{ 
    width: 14vw !important;
    height: 6vh !important;
} */
  /* .upload_icon{
  font-size: 20px!important;
}  */
  /* .button_img_div{
  padding:0px 134px 0px 35px;
} */
  .simpleButton {
    width: 60px;
    height: 36px;
    padding: 0px;
    font-size: 12px;
  }
  .delete_btn {
    width: 60px;
    height: 36px;
    padding: 0px;
    font-size: 13px;
  }
  .delete_img {
    margin-top: 3px;
    margin-right: 0px;
    margin-left: 4px;
  }
  .image-height {
    height: 9.5vh;
  }
  .simpleSubmitButton {
    width: 60px !important;
    height: 36px;
    padding: 0px;
    font-size: 12px !important;
    margin-left: 92px;
  }
  .commentSpan {
    margin-left: 10px;
  }
  .comment_ico {
    font-size: 14px;
  }
  .comment_header {
    font-size: 20px;
  }
  .main_image {
    height: 122px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .simpleInput {
    padding: 0px;
    font-size: 7px;
  }
  .main {
    margin-right: 0px;
  }
  .comment_header {
    font-size: 12px;
  }
  .main_image {
    height: 90px;
  }
  .upload_icon {
    font-size: 24px;
    margin-left: -15px;
    margin-bottom: 25px;
  }
  .image-height {
    height: 7vh;
  }
  .image-padding {
    padding: 0px;
  }
  .simpleButton {
    width: 43px;
    height: 19px;
    padding: 0px;
    font-size: 8px;
  }
  .delete_btn {
    width: 43px;
    height: 19px;
    padding: 0px;
    font-size: 8px;
    margin-right: -36px;
  }
  .delete_img {
    margin-right: -38px;
  }
  .nameEdit_div {
    width: 50%;
    font-size: 10px;
    margin-bottom: -10px;
  }
  .imgedit_div {
    width: 70%;

    margin-left: -41px;
    margin-top: 7px;
  }
  .simpleSubmitButton {
    width: 43px;
    height: 26px;
    padding: 0px;
    font-size: 8px;
    margin-left: 50px;
  }
  .name_div {
    width: 30%;
  }
  .headerSpan {
    font-size: 8px;
  }
  .test {
    height: 46px;
  }
  .best {
    height: 46px;
  }
  .commentSpan {
    font-size: 6px;
    margin-bottom: 0px;
  }
  .comment_ico {
    font-size: 6px;
  }
  .commentTime {
    font-size: 6px;
    margin-right: 0px;
  }
}
@media (min-width: 2048px) {
  /* .simpleSubmitButton{
    margin-left: 215px;

  } */
  /* .image-padding {
    padding-left: 215px;
  } */

  /* .image-padding{
    padding:0px 125px;
  } */
  .main_image {
    height: 142px;
  }
  .commentTime {
    margin-left: 48px;
  }
  .test {
    border: 2px solid red;
    height: 92px;
  }

  .best {
    border: none;
    height: 92px;
  }
  /* .imageheader_main{
    margin: 25px -8px 15px 64px;
  } */
}
