.service_bar {
  margin-bottom: 53px;
}
.row .container {
  margin: 0 auto;
  max-width: 1320px;
}

.mark_head {
  font-size: 40px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 4px;
  line-height: inherit;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 50px;
  font-weight: 800;
  font-family: "Zen Kaku Gothic New", sans-serif;
}

.pervice-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  padding-top: 30px;
}

.os_content1 {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 14px;
  color: #000;
  text-align: left;
  margin: 40px 0 50px;
  line-height: 30px;
}
.os_content {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 18px;
  color: #000;
  text-align: center;
  margin: 40px 0 50px;
  line-height: 30px;
}
.price-img {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 0px;
}

.point_cont1 {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  letter-spacing: 1px;
  line-height: 22px;
  margin-bottom: 22px;
}
.point_title span {
  border: 3px solid rgba(217, 217, 217, 1);
  border-radius: 7px;
  padding: 18px 98px;
  position: relative;
  font-size: 25px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 2px;
  line-height: 35px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 700;
}
.os_content1 {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 14px;
  color: #808080;
  text-align: left;
  margin: 40px 0 50px;
  line-height: 30px;
}
.img_length1 {
  width: 30%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.img_length2 {
  width: 70%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.os_content6 {
  font-size: 16px;
  font-family: "Zen kaku gothic new", sans-serif;
  line-height: 22px;
  text-align: left;
  color: black;
  width: 90%;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-top: 6px;
  font-weight: 700;
}
.img_length10 {
  width: 40%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
