p {
  margin-bottom: 0px;
}
.service_bar1 {
  margin-bottom: 20px;
}
.service_bar2 {
  margin-bottom: 0px;
}
.row .container {
  margin: 0 auto;
  max-width: 1320px;
}

.mark_head1 {
  font-size: 56px;
  color: black;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: 800;
  font-family: "Zen Kaku Gothic New", sans-serif;
}
.sub_title {
  color: #000;
  font-size: 20px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  text-align: center;
  line-height: 30px;
  padding-top: 25px;
  padding-bottom: 25px;
  font-weight: 500;
}
.sub_title1 {
  color: #000;
  font-size: 20px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  text-align: center;
  line-height: 30px;
  padding-top: 10px;
  padding-bottom: 25px;
  font-weight: 500;
}
.sub_title1::before {
  content: url("../../../assets/images/profile-icon.png");
  position: relative; /*or absolute*/
  z-index: 100000; /*a number that's more than the modal box*/
  left: -10px;
  top: 15px;
}
.span-title {
  color: #585858;
  font-size: 14px;
  font-family: "Zen Kaku Gothic New", sans-serif;
  text-align: center;
}
.pervice-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 96%;
  padding-top: 30px;
}
.extra {
  padding-top: 0px !important;
}
.pervice-img1 {
  display: block;
  /* margin-left: auto; */
  margin-right: auto;
  width: 96%;
  padding-top: 78px;
}
.os_content1 {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 14px;
  color: #000;
  text-align: left;
  margin: 40px 0 50px;
  line-height: 30px;
}
.os_content {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 18px;
  color: #000;
  text-align: center;
  margin: 40px 0 50px;
  line-height: 30px;
}
.price-img {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 0px;
}

.point_cont1 {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  letter-spacing: 1px;
  line-height: 22px;
  margin-bottom: 22px;
}
.point_title span {
  border: 3px solid rgba(217, 217, 217, 1);
  border-radius: 7px;
  padding: 18px 40px;
  position: relative;
  font-size: 25px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 2px;
  line-height: 35px;
  font-family: "Noto Sans JP", sans-serif;
}
.os_content1 {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 14px;
  color: #808080;
  text-align: left;
  margin: 40px 0 50px;
  line-height: 30px;
}
.img_length1 {
  width: 36%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}
.img_length2 {
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.os_content3 {
  font-size: 16px;
  font-family: "Zen kaku gothic new", sans-serif;
  line-height: 22px;
  text-align: left;
  color: black;
  width: 90%;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding-top: 60px;
  font-weight: 600;
}
.mark_logo1 img {
  width: 38%;
  margin-top: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.line {
  width: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.line1 {
  width: auto;
  display: block;
  /* margin-left: auto; */
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
.main_title {
  font-size: 49px;
  text-align: center;
  font-family: "Zen Kaku Gothic New", sans-serif;
  color: BLACK;
  font-weight: 700;
  margin-top: 80px;
}
.main_sub_t {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  line-height: 26px;
  color: black;
  font-family: "Zen Kaku Gothic New", sans-serif;
}
.background {
  background-color: #d8d8d8;
  padding-bottom: 80px;
}
.background-g {
  background-color: #00964b;
  padding-bottom: 80px;
}
.background-b {
  background-color: black;
  padding-bottom: 50px;
  padding-top: 50px;
}
.imagebg {
  background-image: url(../../../assets/images/ScrollGroup1.png);
  background-position: bottom 0px right 20px;
  background-repeat: no-repeat;
  background-size: 20%;
}
.imagebg2 {
  background-image: url(../../../assets/images/ScrollGroup2.png);
  background-position: bottom 0px right 20px;
  background-repeat: no-repeat;
  background-size: contain;
}
.background-w {
  background-color: #ffffff;
  padding-bottom: 80px;
  padding-top: 80px;
}
.background-y {
  background-color: #f8f8f8;
  padding-bottom: 80px;
}
.background-l {
  background-color: #efefef;
  padding-bottom: 80px;
  padding-top: 80px;
}
.img_length3 {
  width: 30%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 90px;
  padding-top: 40px;
}
.img_length4 {
  width: 70%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
.img_length5 {
  width: 20%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
.img_length6 {
  width: 60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 0px;
}
.service {
  margin-top: 20px !important;
  margin-bottom: 0px;
}
.descp {
  font-size: 16px;
  line-height: 24px;
  color: black;
  font-weight: 700;
  padding: 30px 0px 30px 0px;
}
.span-text {
  text-decoration: underline;
  border: none;
  border-radius: 0px;
  padding: 0px;
  position: relative;
  font-size: 16px;
  color: #000;
  letter-spacing: 0px;
  line-height: 20px;
  font-weight: 700;
}
.white-color {
  color: white !important;
}
.work-title {
  letter-spacing: 0.38px;
  text-align: left;
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 700;
  padding-top: 8px;
}
.span-text {
  text-decoration: underline;
  border: none !important;
  border-radius: 0px !important;
  padding: 0px !important;
  position: relative !important;
  font-size: 16px !important;
  color: #000 !important;
  letter-spacing: 0px !important;
  line-height: 20px !important;
  font-weight: 700 !important;
}
/* .main_sub_t:before {
  position: absolute;
  content: "";
  width: 34px;
  height: 1px;
  background-color: #707070;
  bottom: -44px;
  display: inline;

  left: 50%;
  transform: translate(-50%, -25px);
} */

.j-title {
  font-family: "Zen kaku gothic new", sans-serif;
  font-size: 20px;
  font-weight: 800;
  text-align: left;
}

.m-subtitle {
  font-family: "Zen kaku gothic new", sans-serif;
  font-size: 27px;
  font-weight: 800;
  text-align: left;
  padding-top: 6px;
  color: white;
}
.m-subtitle span {
  background-color: #009a4d;
  padding-right: 5px;
  padding-left: 5px;
}
.m-descp {
  font-family: "Zen kaku gothic new", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  padding-top: 40px;
}
.special1 {
  max-width: 1000px;
  padding-top: 60px;
}
.special2 {
  max-width: 800px;
}
.m-title {
  font-size: 49px;
  font-style: italic;
  color: #009a4d;
  font-family: "Poppins", sans-serif;
  text-align: left;
}

.m-title1 {
  font-size: 49px;
  font-style: italic;
  color: #009a4d;
  font-family: "Poppins", sans-serif;
  text-align: left;
}

.m-title2 {
  font-size: 49px;
  font-style: italic;
  color: #009a4d;
  font-family: "Poppins", sans-serif;
  text-align: left;
}

.contact {
  min-height: 160px;
  padding-top: 40px;
  padding-bottom: 40px;
  background-image: url(../../../assets/images/contact-bg.png); /* 背景画像指定 */
  background-repeat: no-repeat; /* 背景の繰り返し設定 */
  background-position: center center;
}
.con-title {
  font-size: 49px;
  color: white;
  font-style: italic;
  font-family: "Poppins", sans-serif;
  text-align: left;
  font-weight: 700;
}
.con-sub {
  font-size: 18px;
  color: white;
  text-align: left;
  font-family: "Zen kaku gothic new", sans-serif;
  font-weight: 700;
}
.con-sub span {
  font-size: 32px;
  color: white;
  text-align: left;
  text-decoration: underline;
  font-family: "Zen kaku gothic new", sans-serif;
}
/* .mark_head1:before {
  content: "";
  display: inline-block;
  width: 28px;
  height: 28px;
  background-image: url(../../../assets/images/mypage.png);
  background-size: contain;
  vertical-align: middle;
  margin-right: 10px;
} */
.margin {
  margin-top: -35px !important;
}
.con-sub span a {
  color: white;
}
.con-sub span a:hover {
  color: white;
}
.greenTitle {
  color: #009a4d;
  font-size: 56px;
  line-height: 60px;
  font-weight: 600;
  font-family: "Zen kaku gothic new", sans-serif;
  text-align: center;
}
.underlinetext {
  font-size: 15px;
  font-weight: 600;
  font-family: "Zen kaku gothic new", sans-serif;
  color: black;
  text-align: center;
}
.underlinetext span {
  text-decoration: underline;
}
.img_length8 {
  width: 30%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0px;
  padding-top: 40px;
}
.bg-img {
  background-image: url(../../../assets/images/Ellipse59.png); /* 背景画像指定 */
  background-repeat: no-repeat; /* 背景の繰り返し設定 */
  background-position: center center;
  background-size: auto;
}
.spacing {
  padding-bottom: 60px;
}
.reservation-btn {
  bottom: 120px;
  right: 10px;
  display: block;
  width: 300px;
  position: fixed;
}
.reservation-btn1 {
  bottom: 65px;
  right: 10px;
  display: block;
  width: 300px;
  position: fixed;
}
.reservation-btn2 {
  bottom: 10px;
  right: 10px;
  display: block;
  width: 300px;
  position: fixed;
}
